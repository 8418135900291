import firebase from 'firebase/app'
import 'firebase/performance'

export let perf = null

export const initPerformanceMonitoring = () => {
  if (navigator.doNotTrack === '1') {
    console.log('Do Not Track is enabled, disabling performance monitoring')
    return
  }

  try {
    perf = firebase.performance()
  } catch (error) {
    // ignore
  }
}
