import React from 'react'
import { Box, Flex } from 'rebass'
import { width as actionWidth } from './ActionCard'
import { ListButtons } from './ListButtons'
import { ListHeading } from './ListHeading'
import useCustomScrollbars, {
  scrollbarWidth
} from '../utils/useCustomScrollbars'
import { darken } from 'polished'
import FocusTrap from '../utils/FocusTrap'

export const padding = 20
const paddingBetweenActionAndScrollbar = 5

const Scrollable = React.forwardRef(({ bg, children, ...props }, ref) => {
  const [localRef, scrollbarsShown, scrollbarStyles] = useCustomScrollbars(
    children,
    ref,
    bg
  )

  return (
    <Box
      pr={
        padding -
        paddingBetweenActionAndScrollbar -
        (scrollbarsShown ? scrollbarWidth : 0)
      }
      css={{
        // minimum height of four Actions
        minHeight: `${82 * 4}px`,

        // depends on browser height
        maxHeight: 'calc(90vh - 340px)',

        // for centering "no actions" message
        height: '100%',
        width: '100%',

        'overflow-y': 'auto',

        ...scrollbarStyles
      }}
      ref={localRef}
      children={children}
      {...props}
    />
  )
})

// TODO fix scroll behaviour
// const Container = ScrollContainer(Scrollable)
const Container = Scrollable

export const ListContainer = React.memo(
  React.forwardRef(({ css, ...props }, ref) => (
    <Flex
      pt={130}
      pl={padding}
      pb={120}
      pr={`${paddingBetweenActionAndScrollbar}px`}
      m={1}
      flex={1}
      alignItems="center"
      // react-beautiful-dnd does not work with vertically centered lists yet
      // justifyContent="center"
      flexDirection="column"
      ref={ref}
      css={{
        minWidth: `${actionWidth + padding * 2}px`,
        borderRadius: '14px',
        breakInside: 'avoid-column',
        position: 'relative',
        ...css
      }}
      {...props}
    />
  ))
)

ListContainer.defaultProps = {
  css: {}
}

const getListBG = (bg, isDraggingOver) => {
  let darkness = isDraggingOver ? 0.08 : 0.05
  if (bg === '#FFFFFF') darkness += 0.03

  return darken(darkness, bg)
}

const List = React.memo(
  React.forwardRef(
    (
      {
        bg,
        color,
        title,
        isShared,
        sharedWith,
        children,
        isDraggingOver,
        onAddButtonClick,
        onSettingsButtonClick,
        onFocusTrapBlur = () => {},
        onChooseColorButtonClick,
        onChangeTitle,
        css,
        droppableProps,
        newActionIsVisible,
        showSettings = true,
        ...rest
      },
      ref
    ) => (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: () => onFocusTrapBlur(),
          clickOutsideDeactivates: true,
          escapeDeactivates: true
        }}
        active={showSettings}
      >
        <ListContainer
          color={color}
          bg={getListBG(bg, isDraggingOver)}
          css={{
            // boxShadow: '0 0 16px -7px rgba(0,0,0,0.4)',
            '.button': {
              opacity: 0
            },
            '&:focus-within .button, &:hover .button': {
              // disable showing buttons on hover when dragging over
              opacity: isDraggingOver ? 0 : 1
            }
          }}
          {...droppableProps}
          {...rest}
          ref={ref}
          role="region"
          aria-label={title}
        >
          <ListHeading
            bg={bg}
            color={color}
            onChangeTitle={onChangeTitle}
            placeholder="New List Title"
            title={title}
            sharedWith={sharedWith}
            inSettingsMode={showSettings}
          />
          <Container bg={bg}>{children}</Container>
          {showSettings ? null : (
            <ListButtons
              color={color}
              bg={bg}
              onAddButtonClick={onAddButtonClick}
              onSettingsButtonClick={onSettingsButtonClick}
              onChooseColorButtonClick={onChooseColorButtonClick}
              newActionIsVisible={newActionIsVisible}
            />
          )}
        </ListContainer>
      </FocusTrap>
    )
  )
)

List.defaultProps = {
  bg: '#1574B9',
  isDraggingOver: false
}

export default List
