import React, { forwardRef, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { setActionsPreference } from '../actions'
import { DarkModeButton as DarkModeButtonInner } from './ui/DarkModeButton'

export const Button = forwardRef(
  ({ darkMode, setPreference, ...rest }, ref) => {
    useEffect(
      () => {
        ReactTooltip.hide(ref)
      },
      [darkMode, ref]
    )
    return (
      <DarkModeButtonInner
        ref={ref}
        enabled={darkMode}
        onClick={e => setPreference({ darkMode: !darkMode })}
        aria-label={darkMode ? 'Set light mode' : 'Set dark mode'}
        data-tip={darkMode ? 'Light mode' : 'Dark mode'}
        data-place="bottom"
        data-delay-show="300"
        data-offset="{&quot;top&quot;: -10}"
        {...rest}
      />
    )
  }
)

const mapStateToProps = state => ({
  darkMode: get(state, 'preferences.actions.darkMode', false)
})
const DarkModeButton = connect(
  mapStateToProps,
  { setPreference: setActionsPreference }
)(Button)

export default DarkModeButton
