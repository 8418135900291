import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import { Flex } from 'rebass'
import { UndoIcon, UndoCircle } from './icons'
import { transparentize } from 'polished'

const transition = {
  pathLength: {
    ease: 'linear',
    duration: 10000
  }
}

const PosedUndoCircle = posed(UndoCircle)({
  initial: {
    pathLength: 1
  },
  enter: {
    pathLength: 0,
    transition
  }
})

const UndoButtonInner = React.forwardRef(
  ({ title, size = 30, actionId, darkMode = true, onClick }, ref) => (
    <Flex
      is="button"
      justifyContent="center"
      alignItems="center"
      p={0} // override button default
      mx={2}
      ref={ref}
      aria-label={`Undo deleting "${title}"`}
      data-tip={`Undo deleting ${title}`}
      css={{
        position: 'relative',

        // override calculated transformOrigin due to bug
        transformOrigin: '50% 50% !important',

        // button styles
        backgroundColor: 'transparent',
        border: 'none',
        color: 'inherit',
        borderRadius: '50%',
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'all 0.2s ease',
        '&:hover, &:focus': {
          border: 'none',
          outline: 'none',
          'svg#arrow': { transform: 'scale(0.85)' }
        }
      }}
      onClick={onClick}
    >
      <PosedUndoCircle
        width={size}
        height={size}
        color={transparentize(0.5, darkMode ? '#fff' : '#000')}
        style={{ position: 'absolute', width: size, height: size }}
      />
      <UndoIcon
        id="arrow"
        height={18}
        width={18}
        color={darkMode ? '#fff' : '#000'}
      />
    </Flex>
  )
)

export const UndoButton = posed(UndoButtonInner)({
  enter: {
    scale: 1,
    rotate: 0
  },
  exit: {
    scale: 0,
    rotate: '-90deg',
    transition: { scale: { delay: 200 }, rotate: { ease: 'linear' } }
  }
})

export const UndoButtonWrapper = props => (
  <Flex flexDirection="row-reverse">
    <PoseGroup preEnterPose="initial" animateOnMount={true} {...props} />
  </Flex>
)
