import { get, every } from 'lodash'

const eqTrue = (obj, key) => get(obj, key, false) === true

// If firebase queries have loaded
export const isFullyLoaded = action => {
  if (!action) return false
  const { firestore, firebase } = action

  // Listen to firestore query for actions and lists
  const actionsLoaded = eqTrue(firestore, 'status.requested.actions')
  const listsLoaded = eqTrue(firestore, 'status.requested.lists')

  // Listen to rtdb query for /users/uid, for install date & schema version
  const profileLoaded = eqTrue(firebase, 'profile.isLoaded')

  return every([actionsLoaded, listsLoaded, profileLoaded])
}
