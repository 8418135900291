import {
  REORDER_IN_SCHEDULE,
  REORDER_IN_LIST,
  MOVE_BETWEEN_LISTS,
  MOVE_FROM_LIST_TO_SCHEDULE,
  CREATE_LIST,
  DELETE_LIST_START,
  UNDELETE_LIST,
  LIST_CHANGE_THEME,
  LIST_CHANGE_TITLE,
  LIST_CHANGE_ARCHIVED,
  CREATE_ACTION_IN_LIST,
  CREATE_ACTION_IN_SCHEDULE,
  TOGGLE_COMPLETE_ACTION,
  DELETE_ACTION_START,
  UNDELETE_ACTION,
  CHANGE_ACTION_TITLE,
  CHANGE_ACTION_NOTES,
  CHANGE_ACTION_DATE,
  CHANGE_ACTION_DEADLINE,
  DELETE_ACTION_CANCEL,
  CHANGE_ACTION_REPEAT,
  CHANGE_ACTION_REMINDER,
  CHANGE_PREFERENCE,
  CHANGE_USER_SETTING,
  TOGGLE_COMPLETE_SUBTASK,
  CHANGE_SUBTASK_TITLE,
  DELETE_SUBTASK,
  CREATE_SUBTASK,
  REORDER_SUBTASK,
  ADD_GOOGLE_ACCOUNT,
  REMOVE_GOOGLE_ACCOUNT,
  STORE_GOOGLE_EVENTS,
  TOGGLE_GOOGLE_CALENDAR,
  SHARE_LIST,
  UNSHARE_LIST,
  REMOVE_LIST_USER,
  ACCEPT_INVITE,
  GET_SHARING_PREVIEW,
  CHANGE_SHARING_NAME,
  CHANGE_ACTION_PRIORITY,
  TOGGLE_LIST_NOTIFICATION_SETTING,
  CLEAR_RECENTLY_DELETED_START
} from '../actions/types'

// action creators

// drag and drop
export const reorderInSchedule = ({
  actionId,
  sourceDay,
  sourceIndex,
  destDay,
  destIndex
}) => ({
  type: REORDER_IN_SCHEDULE,
  data: {
    actionId,
    sourceDay,
    sourceIndex,
    destDay,
    destIndex
  }
})

export const reorderInList = ({
  actionId,
  listId,
  destIndex,
  sourceIndex
}) => ({
  type: REORDER_IN_LIST,
  data: {
    actionId,
    listId,
    destIndex,
    sourceIndex
  }
})

export const moveBetweenLists = ({
  actionId,
  sourceList,
  destList,
  destIndex
}) => ({
  type: MOVE_BETWEEN_LISTS,
  data: {
    actionId,
    sourceList,
    destList,
    destIndex
  }
})

export const moveFromScheduleToList = ({ actionId, destList, destIndex }) => ({
  type: MOVE_BETWEEN_LISTS,
  data: { actionId, destList, destIndex }
})

export const moveFromListToSchedule = ({ actionId, destDay, destIndex }) => ({
  type: MOVE_FROM_LIST_TO_SCHEDULE,
  data: { actionId, destDay, destIndex }
})

// action operations
export const toggleCompleted = ({ actionId, isCompleted }) => ({
  type: TOGGLE_COMPLETE_ACTION,
  data: { actionId, isCompleted }
})

export const deleteAction = ({ actionId }) => ({
  type: DELETE_ACTION_START,
  data: { actionId }
})

export const clearRecentlyDeleted = () => ({
  type: CLEAR_RECENTLY_DELETED_START
})

export const deleteList = ({ listId }) => ({
  type: DELETE_LIST_START,
  data: { listId }
})

export const restoreDeletedList = ({ listId, actionIds }) => ({
  type: UNDELETE_LIST,
  data: { listId, actionIds }
})

export const cancelDeleteAction = ({ actionId }) => ({
  type: DELETE_ACTION_CANCEL,
  data: { actionId }
})

export const restoreDeletedAction = ({ actionId }) => ({
  type: UNDELETE_ACTION,
  data: { actionId }
})

export const changeActionTitle = ({ actionId, title }) => ({
  type: CHANGE_ACTION_TITLE,
  data: { actionId, title }
})

export const changeActionNotes = ({ actionId, notes }) => ({
  type: CHANGE_ACTION_NOTES,
  data: { actionId, notes }
})

export const changeActionList = ({ actionId, newListId }) => ({
  type: MOVE_BETWEEN_LISTS,
  data: { actionId, destList: newListId, destIndex: 0 }
  // TODO always moves to first in list
})

export const changeActionDate = ({ actionId, newDate }) => ({
  type: CHANGE_ACTION_DATE,
  data: { actionId, date: newDate }
})

export const changeActionDeadline = ({ actionId, deadline }) => ({
  type: CHANGE_ACTION_DEADLINE,
  data: { actionId, deadline }
})

export const changeActionRepeat = ({ actionId, newRepeat }) => ({
  type: CHANGE_ACTION_REPEAT,
  data: { actionId, repeat: newRepeat }
})

export const changeActionReminder = ({ actionId, reminder }) => ({
  type: CHANGE_ACTION_REMINDER,
  data: { actionId, reminder }
})

export const changeActionPriority = ({ actionId, priority }) => ({
  type: CHANGE_ACTION_PRIORITY,
  data: { actionId, priority }
})

// list operations
export const changeListTheme = ({ listId, themeId }) => ({
  type: LIST_CHANGE_THEME,
  data: { listId, themeId }
})
export const changeListTitle = ({ listId, title }) => ({
  type: LIST_CHANGE_TITLE,
  data: { listId, title }
})
export const changeListArchived = ({ listId, archived }) => ({
  type: LIST_CHANGE_ARCHIVED,
  data: { listId, archived }
})
export const createList = ({ title, theme }) => ({
  type: CREATE_LIST,
  data: { title, theme }
})
export const toggleListNotificationSetting = ({
  listId,
  notificationsEnabled
}) => ({
  type: TOGGLE_LIST_NOTIFICATION_SETTING,
  data: { listId, notificationsEnabled }
})

export const createActionInList = ({ title, listId, top }) => ({
  type: CREATE_ACTION_IN_LIST,
  data: { title, listId, top }
})

export const createActionInSchedule = ({ title, date, top }) => ({
  type: CREATE_ACTION_IN_SCHEDULE,
  data: { title, date, top }
})

export const setActionsPreference = pref => ({
  type: CHANGE_PREFERENCE,
  data: { actions: pref }
})

export const changeUserSetting = preference => ({
  type: CHANGE_USER_SETTING,
  data: preference
})

// subtask operations
export const subtaskToggleComplete = ({
  actionId,
  subtaskId,
  isCompleted
}) => ({
  type: TOGGLE_COMPLETE_SUBTASK,
  data: { actionId, subtaskId, isCompleted }
})
export const changeSubtaskTitle = ({ actionId, subtaskId, title }) => ({
  type: CHANGE_SUBTASK_TITLE,
  data: { actionId, subtaskId, title }
})
export const deleteSubtask = ({ actionId, subtaskId }) => ({
  type: DELETE_SUBTASK,
  data: { actionId, subtaskId }
})
export const createSubtask = ({ actionId, title }) => ({
  type: CREATE_SUBTASK,
  data: { actionId, title }
})
export const reorderSubtask = ({
  actionId,
  subtaskId,
  sourceIndex,
  destIndex
}) => ({
  type: REORDER_SUBTASK,
  data: { actionId, subtaskId, sourceIndex, destIndex }
})

export const addGoogleAccount = ({ code }) => ({
  type: ADD_GOOGLE_ACCOUNT,
  data: { code }
})

export const storeGoogleEvents = ({ events }) => ({
  type: STORE_GOOGLE_EVENTS,
  data: { events }
})

export const removeGoogleAccount = ({ account }) => ({
  type: REMOVE_GOOGLE_ACCOUNT,
  data: { account }
})

export const toggleCalendarVisibility = ({ calendar }) => ({
  type: TOGGLE_GOOGLE_CALENDAR,
  data: { calendar }
})

// sharing
export const shareList = ({ listId, then }) => ({
  type: SHARE_LIST,
  data: { listId, then }
})
export const unshareList = ({ listId }) => ({
  type: UNSHARE_LIST,
  data: { listId }
})
export const removeListUser = ({ listId, userId }) => ({
  type: REMOVE_LIST_USER,
  data: { listId, userId }
})
export const acceptInvite = ({ sharingToken }) => ({
  type: ACCEPT_INVITE,
  data: { sharingToken }
})
export const getSharingPreview = ({ sharingToken }) => ({
  type: GET_SHARING_PREVIEW,
  data: { sharingToken }
})
export const changeSharingName = ({ name }) => ({
  type: CHANGE_SHARING_NAME,
  data: { name }
})
