import posed from 'react-pose'
export { PoseGroup } from 'react-pose'

const transition = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
  restDelta: 0.5,
  restSpeed: 10
}

export const SlideLeftTransition = posed.div({
  enter: {
    x: '0%',
    transition,
    delay: 200, // delay enter until previous has exited
    applyAtStart: { position: 'absolute' },
    applyAtEnd: { transform: null, x: 0, position: 'initial' }
  },
  exit: {
    x: '-120%',
    transition,
    applyAtStart: { position: 'absolute' },
    applyAtEnd: { transform: null, position: 'initial' }
  }
})

export const PreferencesPageTransition = posed.div({
  enter: {
    x: '0%',
    transition,
    delay: 200
  },
  exit: {
    x: '-400px',
    transition
  }
})
