import { useEffect } from 'react'
import { environment, FathomToken } from './config'
import * as Fathom from 'fathom-client'
import * as Sentry from '@sentry/browser'
import { sanitisePath } from './utils/href'

const testMode =
  process.env.NODE_ENV === 'test' || environment === 'development'

const log = false

const FathomEventIDs =
  environment === 'production'
    ? {
        'Action Completed': 'M6ETKWFO',
        'Action Created': 'EWOMJCJZ',
        'Action Opened': '0MKD8EWY',
        'Action Deleted': 'C44LZK2E',
        'Action Deletion Cancelled': 'LQHOMORO',
        'Action Uncompleted': '1G4VDCC5',
        'Action View Notes': 'ERPUIZFW',
        'Action View Reminders': 'PWWABELK',
        'Action View Schedule': 'DTXSCPU3',
        'List Created': '4YHW8XK2',
        'List View Colorpicker': 'FSKKIVNT',
        Login: '3I5BLAJV',
        Logout: 'IYCSEDEC',
        'Subtask Completed': 'FLM576XJ',
        'Subtask Created': 'FHP2WKX8',
        'Support Opened': 'F6QQMFR1'
      }
    : // staging
      {
        'Action Completed': 'VHMWE1DP',
        'Action Created': 'TF0ZJIXS',
        'Action Opened': '2ZDVO5AT',
        'Action Deleted': 'LHBRZ11L',
        'List Created': 'ICYTOMKR',
        'Action Deletion Cancelled': 'CCD0RKHD',
        'Action Uncompleted': 'VMKTJFDB',
        'Action View Notes': 'YC0IRHHS',
        'Action View Reminders': 'HDWVUVKS',
        'Action View Schedule': 'RFWY6NH2',
        'List View Colorpicker': 'ROHVJK3G',
        Login: '1UDRPWE6',
        Logout: 'BTVWD32N',
        'Subtask Completed': 'JNLHSW1M',
        'Subtask Created': '6OTPKYDS',
        'Support Opened': '1FJGHP17'
      }

export const initialise = () => {
  if (!FathomToken) return

  if (testMode) {
    if (log) console.log('Skipping Fathom setup, in test mode')
  } else {
    Fathom.load(FathomToken, { auto: false })
  }
}

export const setAnalyticsUser = userId => {
  // Do nothing - Fathom has no userIds
}

export const pageView = location => {
  // sanitise url - remove Action Ids
  const url = sanitisePath(location)

  if (testMode) {
    if (log) console.log(`Analytics test mode: Pageview - ${url}`)
    return
  }

  Fathom.trackPageview({ url })
}

const trackGoal = event => {
  if (!window.fathom || !window.fathom.beacon) return

  const code = event
  const cents = 0
  const hostname = window.location.protocol + '//' + window.location.hostname
  const siteId = FathomToken

  // sanitised pathname
  const pathname = sanitisePath(window.location.pathname)

  window.fathom.beacon({
    gcode: code,
    gval: cents,
    qs: JSON.stringify({}), // no query string
    p: pathname,
    h: hostname,
    r: document.referrer.indexOf(hostname) < 0 ? document.referrer : '',
    sid: siteId
  })
}

export const trackEvent = eventName => {
  const eventId = FathomEventIDs[eventName]

  if (testMode) {
    if (log)
      console.log(`Analytics test mode: Event - ${eventName} (${eventId})`)
    return
  }

  if (eventId) {
    // use direct method so we have control over pathname sent to fathom
    trackGoal(eventId)
  }

  Sentry.addBreadcrumb({
    message: eventName,
    level: Sentry.Severity.Info
  })
}

export const TrackEvent = ({ action: eventName }) => {
  useEffect(
    () => {
      trackEvent(eventName)
    },
    [eventName]
  )
  return null
}

export function* trackEventAsync(eventName) {
  trackEvent(eventName)
  yield
}

initialise()
