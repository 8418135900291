import { mapValues, isEqual } from 'lodash'
import { pick, map } from 'lodash/fp'

// props used to compare Action equality
// updatedAt intentionally ignored
const actionComparisonProps = [
  'title',
  'completed',
  'identifier',
  'owner',
  'title',
  'notes',

  'deadlineDate',
  'priority',

  'subtasksCompleted',
  'subtasksTotal',
  'subtasks',

  // sorting
  'displayIndex',
  'scheduleDisplayIndex',

  'due',
  'markedForDeletion',

  'list', // ID
  'listData.themeId',
  'listData.title',

  // repeats
  'repeat',

  // rems
  'myData.reminders'
]

export const cleanAction = pick(actionComparisonProps)

const cleanActionList = map(cleanAction)

export const actionListsAreEqual = (list1, list2) => {
  if (list1.length !== list2.length) {
    return false
  }

  return isEqual(cleanActionList(list1), cleanActionList(list2))
}

const listComparisonProps = [
  'title',
  'themeId',
  'owner',

  // sharing
  'sharingToken',
  'userIds'
]

export const cleanList = pick(listComparisonProps)

export const listsAreEqual = (list1, list2) =>
  isEqual(cleanList(list1), cleanList(list2))

export const scheduleActionsAreEqual = (schedule1, schedule2) => {
  return isEqual(
    mapValues(schedule1, cleanActionList),
    mapValues(schedule2, cleanActionList)
  )
}

export const actionsAreEqual = (a1, a2) =>
  isEqual(cleanAction(a1), cleanAction(a2))
