import { get, mapValues, merge, pick, values } from 'lodash'
import {
  themes,
  defaultDarkTheme,
  defaultLightTheme,
  themeNameMapping
} from '../constants/actionsThemeColours'

const formatTheme = theme => pick(theme, ['bg', 'text'])

export const getTheme = (listTheme, darkMode = false) => {
  const defaultTheme = darkMode ? defaultDarkTheme : defaultLightTheme

  // hidden list case
  if (listTheme == null) return formatTheme(defaultTheme)

  // map name from old to new, if necessary
  const themeName = get(themeNameMapping, listTheme, listTheme)

  // get theme, or fall back to dark theme (for missing theme Id)
  return formatTheme(get(themes, themeName, defaultDarkTheme))
}

export const getThemeCategory = themeName =>
  get(themes, [themeName, 'category'], 'vivid')

export const getThemeList = () => {
  // exports indexable list of theme objects, with object key as name
  // used by ColorPicker
  // {'Antwerp Blue': {
  //   bg: '#1574B9',
  //   text: '#FFFFFFFF'
  // }, ...}
  // -->
  // [{
  //   name: 'Antwerp Blue',
  //   bg: '#1574B9',
  //   text: '#FFFFFFFF'
  // }, ...]
  return values(mapValues(themes, (theme, key) => merge(theme, { name: key })))
}

export const getThemeCategories = () => {
  const uniqueCategorySet = new Set(
    values(mapValues(themes, theme => theme.category))
  )
  return Array.from(uniqueCategorySet)
}

export const getThemeListByCategory = () => {
  const themeListByCategory = {}
  for (const theme of getThemeList()) {
    if (!themeListByCategory[theme.category]) {
      themeListByCategory[theme.category] = []
    }

    themeListByCategory[theme.category].push(theme)
  }
  return themeListByCategory
}
