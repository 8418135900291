import React from 'react'
import { Box } from 'rebass'
import { times } from 'lodash'
import { Flex, Text } from 'rebass'
import { FlexGrow } from './Flex'

const ProgressSummary = ({ completed, total }) => {
  const label = `${completed} of ${total} subtask${
    total > 1 ? 's' : ''
  } complete`

  return (
    <Text
      ml={2}
      fontSize={1}
      aria-label={label}
      lineHeight={1}
      fontWeight={500}
      id="subtask-label"
    >
      <span>{completed}</span>
      &thinsp;/&thinsp;
      <span>{total}</span>
    </Text>
  )
}

const ProgressWrapper = ({ children, completed, total, onClick, big }) => (
  <Flex
    alignItems="center"
    mx="5px"
    flex={1}
    // TODO tabIndex/aria-label/focus states/make into a button
    css={{
      minHeight: '24px',
      cursor: 'pointer',
      transition: 'opacity 0.1s ease',
      '.progressbar': {
        height: big ? '6px' : null
      },
      '&:hover': {
        opacity: 0.8
      }
    }}
    onClick={onClick}
  >
    {children}
    <ProgressSummary completed={completed} total={total} />
  </Flex>
)

const Bar = ({
  leftRadius = false,
  rightRadius = false,
  flex,
  opacity,
  color,
  height = 4,
  ...props
}) => (
  <Box
    bg={color}
    className="progressbar"
    {...props}
    css={{
      flex,
      height,
      opacity,
      borderTopLeftRadius: leftRadius ? '20px' : null,
      borderBottomLeftRadius: leftRadius ? '20px' : null,
      borderTopRightRadius: rightRadius ? '20px' : null,
      borderBottomRightRadius: rightRadius ? '20px' : null
    }}
  />
)

export const DeterminateProgress = ({
  completed,
  total,
  color,
  height = 4
}) => (
  <>
    {times(total, i => (
      <Box
        key={i}
        bg={color}
        mr="2px"
        className="progressbar"
        css={{
          flex: 1,
          height,
          opacity: i < completed ? 1 : 0.4,
          borderTopLeftRadius: i === 0 ? '20px' : 0,
          borderBottomLeftRadius: i === 0 ? '20px' : 0,
          borderTopRightRadius: i === total - 1 ? '20px' : 0,
          borderBottomRightRadius: i === total - 1 ? '20px' : 0,
          transition: '0.5s opacity ease'
        }}
      />
    ))}
  </>
)

export const IndeterminateProgress = ({
  total,
  completed,
  color,
  height = 4
}) => (
  <>
    <Bar
      flex={completed}
      leftRadius={completed > 0}
      rightRadius={total === completed}
      color={color}
      mr="2px"
      pr={total === completed ? '2px' : 0}
      height={height}
    />
    <Bar
      flex={total - completed}
      leftRadius={completed === 0}
      rightRadius={true}
      color={color}
      opacity={0.4}
      pl={completed === 0 ? '2px' : 0}
      height={height}
    />
  </>
)

export const Progressbar = ({
  completed,
  total,
  color,
  onClick,
  big = false
}) => {
  if (total === 0) return <FlexGrow />
  return (
    <ProgressWrapper
      total={total}
      completed={completed}
      onClick={onClick}
      big={big}
    >
      {total <= 10 ? (
        <DeterminateProgress
          total={total}
          completed={completed}
          color={color}
        />
      ) : (
        <IndeterminateProgress
          total={total}
          completed={completed}
          color={color}
        />
      )}
    </ProgressWrapper>
  )
}
