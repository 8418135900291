import sys from '@rebass/components'

export const Fixed = sys(
  {},
  { position: 'fixed', zIndex: 100 },
  'space',
  'color',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',
  'width'
)
