import React, { forwardRef } from 'react'
import sys from '@rebass/components'
import { Flex as BaseFlex } from 'rebass'
import { getContrastingBorder, lightenHSV as lighten } from '../../utils/color'

const buttonSize = 45

const Flex = sys({ extend: BaseFlex }, 'width', 'height', 'borderRadius')

export const CircularButton = forwardRef(
  (
    {
      color,
      bg,
      transparent = false,
      onClick,
      children,
      disabled,
      size = buttonSize,
      css = {},
      ...rest
    },
    ref
  ) => (
    <Flex
      is="button"
      bg={transparent ? 'transparent' : bg}
      className="button"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius="50%"
      tabIndex={0}
      css={{
        fontFamily: 'inherit',
        border: '1px solid transparent',
        boxShadow: transparent ? 'initial' : getContrastingBorder(bg),
        color: 'inherit',

        cursor: disabled ? 'default' : 'pointer',
        userSelect: 'none',
        transition:
          'background-color 0.2s ease, opacity 0.1s cubic-bezier(0.38, 0.07, 0.38, 0.97)',
        svg: { transition: 'all 0.1s ease', transform: 'scale(1)' },
        '&:focus': {
          outline: 'none'
        },
        '&:focus-visible': {
          border: `1px solid ${color}`
        },
        '&:hover': {
          backgroundColor: disabled
            ? null
            : transparent
              ? 'transparent'
              : lighten(0.03, bg)
        },
        '&:active': {
          backgroundColor: disabled
            ? null
            : transparent
              ? 'transparent'
              : lighten(0.05, bg)
        },
        ...css
      }}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {children}
    </Flex>
  )
)

CircularButton.displayName = 'CircularButton'
