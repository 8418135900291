import React, { createContext, useContext, useRef } from 'react'
import { Flex, Box, Absolute } from 'rebass'
import { connect } from 'react-redux'
import { parseURL, buildActionHref } from '../../utils/href'
import { PoseGroup, SlideLeftTransition } from '../utils/transitions'
import { navHeightPx } from '../TopNav'
import { IconLink } from './Link'
import { LogbookIcon, ScheduleIcon } from './icons'
import { width as actionCardWidth } from './ActionCard'
import { getBGColor } from '../../constants/actionsThemeColours'
import mergeRefs from 'react-merge-refs'

import useCustomScrollbars, {
  scrollbarWidth
} from '../utils/useCustomScrollbars'

export const scheduleWidth = actionCardWidth + 16 + 32 + 2 + scrollbarWidth * 2
// e.g. 382

const getURL = (location, toLogbook) => {
  const { actionId, actionSlug } = parseURL(location.pathname)
  if (actionId) {
    return buildActionHref(actionId, actionSlug, toLogbook)
  }

  return toLogbook ? '/logbook' : '/'
}

const ScheduleLink = ({
  location,
  logbookVisible,
  preferencesVisible,
  ...rest
}) => (
  <IconLink
    p="4px"
    to={getURL(location, false)}
    isActive={logbookVisible}
    icon={ScheduleIcon}
    aria-label="Show Schedule"
    data-tip="Show Schedule"
    data-delay-show={300}
    {...rest}
  />
)
const LogbookLink = ({
  location,
  logbookVisible,
  preferencesVisible,
  ...rest
}) => (
  <IconLink
    p="4px"
    // visually centered within focus border
    // since SVG is slightly offset from centre
    pr="3px"
    to={getURL(location, true)}
    isActive={!logbookVisible}
    icon={LogbookIcon}
    aria-label="Show Logbook"
    data-tip="Show Logbook"
    data-delay-show={300}
    {...rest}
  />
)

export const LogbookScheduleSwitcher = connect(({ location }) => ({
  location,
  logbookVisible: location.logbookVisible,
  preferencesVisible: location
}))(({ darkMode, location, logbookVisible, preferencesVisible }) => (
  <Absolute top={0} right={0}>
    <Flex mt={39} mr={34} role="navigation">
      <LogbookLink
        location={location}
        logbookVisible={logbookVisible}
        preferencesVisible={preferencesVisible}
        color={darkMode ? 'white' : 'black'}
      />
      <ScheduleLink
        location={location}
        logbookVisible={logbookVisible}
        preferencesVisible={preferencesVisible}
        color={darkMode ? 'white' : 'black'}
      />
    </Flex>
  </Absolute>
))

const Context = createContext()

export const useGetParentRef = () => {
  const ref = useContext(Context)
  return ref
}

export const LeftPaneWrapper = ({
  children,
  logbookVisible = false,
  darkMode,
  ...props
}) => {
  const localRef = useRef()
  const bg = getBGColor(darkMode)
  const [ref, scrollbarsShown, scrollbarStyles] = useCustomScrollbars(
    children,
    null,
    bg
  )

  return (
    <Box
      width={scheduleWidth}
      pr={scrollbarsShown ? 0 : scrollbarWidth}
      pl={scrollbarWidth}
      css={{
        position: 'relative',
        flexShrink: 0,
        borderRight: darkMode ? '1px solid #262626' : '2px solid #eaeaea',
        height: `calc(100vh - ${navHeightPx})`,
        overflowY: 'auto',
        ...scrollbarStyles
      }}
      ref={mergeRefs([ref, localRef])}
    >
      <LogbookScheduleSwitcher darkMode={darkMode} />
      <Context.Provider value={localRef}>
        <PoseGroup flipMove={false}>
          <SlideLeftTransition key={logbookVisible}>
            {children}
          </SlideLeftTransition>
        </PoseGroup>
      </Context.Provider>
    </Box>
  )
}
