import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Flex } from 'rebass'
import { createGlobalStyle } from 'styled-components'
import { TopNav } from '../TopNav'
import useWindowSize from '../utils/useWindowSize'
import useLocation from '../utils/useLocation'
import { parseURL } from '../../utils/href'
import { getBGColor } from '../../constants/actionsThemeColours'

// fixed sizes
import { scheduleWidth } from './LeftPane'
import { scrollbarWidth } from '../utils/useCustomScrollbars'
import { listWidth } from './Lists'
import { sidebarWidth as sidebarOpenWidth } from './Sidebar'

import BrandonGrotesqueEOT from './fonts/brandon_grotesque_bold.eot'
import BrandonGrotesqueWoff from './fonts/brandon_grotesque_bold.woff'
import BrandonGrotesqueWoff2 from './fonts/brandon_grotesque_bold.woff2'
import BrandonGrotesqueTTF from './fonts/brandon_grotesque_bold.ttf'

import ProximaSoftEOT from './fonts/proximasoft-bold.eot'
import ProximaSoftTTF from './fonts/proximasoft-bold.ttf'
import ProximaSoftWoff from './fonts/proximasoft-bold.woff'
import ProximaSoftWoff2 from './fonts/proximasoft-bold.woff2'

export const FixedBodyStyle = createGlobalStyle`
body {
  max-width: 100%;
  overflow-x: hidden;
}
`

const transition = {
  x: {
    type: 'tween',
    duration: 300,
    ease: 'easeInOut'
  },
  scale: {
    type: 'tween',
    duration: 300,
    ease: 'easeInOut'
  },
  backgroundColor: {
    // dark mode bg transition
    duration: 300
  }
}

const PosedLayoutBox = posed(Flex)({
  initialPose: 'init',
  init: {
    x: 0,
    scale: 1,
    borderRadius: 0,
    transition,
    backgroundColor: ({ bg }) => bg, // tween bg colour
    applyAtEnd: {
      maxHeight: null,
      maxWidth: null,
      cursor: null,
      pointerEvents: 'all'
    }
  },
  preferences: {
    x: 320,
    scale: 0.85,
    borderRadius: '15px',
    backgroundColor: ({ bg }) => bg, // tween bg colour
    applyAtStart: {
      overflow: 'hidden',
      maxHeight: '100vh',
      maxWidth: '100vw',
      pointerEvents: 'none'
    },
    transition
  }
})

const useGetPaddingLeft = () => {
  const windowSize = useWindowSize()
  const { location } = useLocation()

  // handle sidebar open
  const { actionId } = parseURL(location.pathname)
  const sidebarIsVisible = !!actionId

  const [paddingLeft, setPaddingLeft] = useState(0)

  useLayoutEffect(
    () => {
      if (!windowSize) {
        return
      }

      const windowWidth = windowSize.width
      // 40 + 36 are pr + pl for Lists container
      const listsPadding = 40 + 20 + scrollbarWidth
      const sidebarWidth = sidebarIsVisible ? sidebarOpenWidth : 0
      const maxListsWidth =
        windowWidth - scheduleWidth - listsPadding - sidebarWidth
      const numLists = Math.max(1, Math.floor(maxListsWidth / listWidth))
      const listsWidth = numLists * listWidth + listsPadding
      const remainderWidth =
        windowWidth - (listsWidth + scheduleWidth + sidebarWidth)
      const newPaddingLeft = Math.max(0, Math.floor(remainderWidth / 2))

      setPaddingLeft(newPaddingLeft)
    },
    [windowSize, sidebarIsVisible]
  )
  return paddingLeft
}

const StyledTooltip = styled(ReactTooltip)`
  &.place-bottom {
    text-align: center;
  }
`

export const MainLayout = ({
  children,
  preferencesVisible = false,
  darkMode = false
}) => {
  const paddingLeft = useGetPaddingLeft()

  return (
    <Flex flexDirection="column" css={{ height: '100vh' }}>
      <TopNav />
      <PosedLayoutBox
        is="main"
        flex={1}
        bg={getBGColor(darkMode)}
        pose={preferencesVisible ? 'preferences' : 'init'}
        poseKey={`${darkMode.toString()} ${preferencesVisible.toString()}`}
        pl={`${paddingLeft}px`}
        css={{
          minWidth: '810px',
          transformOrigin: 'center left'
        }}
      >
        <Flex flex={1}>{children}</Flex>
        {preferencesVisible ? (
          <FixedBodyStyle />
        ) : (
          <StyledTooltip
            place="bottom"
            type={darkMode ? 'light' : 'dark'}
            effect="solid"
          />
        )}
      </PosedLayoutBox>
    </Flex>
  )
}

MainLayout.displayName = 'MainLayout'

MainLayout.propTypes = {
  mr: PropTypes.number,
  children: PropTypes.node,
  preferencesVisible: PropTypes.bool
}

// TODO move bg colour to theme
export const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    text-size-adjust: 100%;
  }
  ::-moz-focus-inner {
    /* Remove focus ring in FF */
    border: 0;
  }
  body {
    background-color: black;
    margin: 0;
  }

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('${BrandonGrotesqueEOT}');
  src: url('${BrandonGrotesqueEOT}') format('embedded-opentype'),
       url('${BrandonGrotesqueWoff2}') format('woff2'),
       url('${BrandonGrotesqueWoff}') format('woff'),
       url('${BrandonGrotesqueTTF}') format('truetype');
  font-style: normal;
  font-weight: normal;
}

/* used in Deadline screen */
@font-face {
  font-family: 'Proxima Soft';
  src: url('${ProximaSoftWoff2}');
  src: url('${ProximaSoftEOT}') format('embedded-opentype'),
       url('${ProximaSoftWoff2}') format('woff2'),
       url('${ProximaSoftWoff}') format('woff'),
       url('${ProximaSoftTTF}') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
`
