import React from 'react'
import PropTypes from 'prop-types'

export const ChangeColorIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={color}
    role="presentation"
  >
    <circle cx="9.012" cy="14.313" r="4.313" />
    <circle cx="7.574" cy="4.608" r="3.235" />
    <circle cx="16.2" cy="8.742" r="3.055" />
    <circle cx="2.541" cy="9.461" r="1.797" />
    <circle cx="16.111" cy="15.122" r="1.348" />
    <circle cx="13.415" cy="3.44" r="1.348" />
  </svg>
)

ChangeColorIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

ChangeColorIcon.defaultProps = {
  size: '22',
  color: 'currentColor'
}

ChangeColorIcon.displayName = 'ChangeColorIcon'
