import React from 'react'
import PropTypes from 'prop-types'

export const ReminderIcon = ({ size, color, opacity }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill={color}
    fillOpacity={opacity}
  >
    <path d="M9.39,22c.42,1.2,1.65,2.07,3.11,2.07s2.69-.87,3.11-2.07h-6.21Z" />
    <path d="M22.84,17.54c-1.3-1.37-3.88-4.74-3.88-10.04S16.01,.65,12.5,.65s-6.46,1.46-6.46,6.84-2.58,8.68-3.88,10.04c-.85,.89-.27,2.36,1,2.46,.05,0,.1,0,.15,0H21.69c.05,0,.1,0,.15,0,1.27-.1,1.85-1.56,1-2.46Z" />
  </svg>
)

ReminderIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  opacity: PropTypes.number
}

ReminderIcon.defaultProps = {
  size: '25',
  color: 'currentColor',
  opacity: 1
}
