import { memoize, get } from 'lodash'
import slugify from '@sindresorhus/slugify'

const slugifyOpts = {
  customReplacements: [['🐶', 'dog'], ['❤️', 'love']]
}
const memoSlug = memoize(url => slugify(url, slugifyOpts))

export const getActionHref = (action, logbook) => {
  const title = get(action, 'title', '')
  const titleSlug = encodeURIComponent(memoSlug(title))
  return buildActionHref(action.identifier, titleSlug, logbook)
}

export const buildActionHref = (id, slug, logbook) =>
  `${logbook ? '/logbook' : ''}/a/${id}/${slug ? `${slug}/` : ''}`

const regex = '(?:/(logbook))?(?:/a/([^/]+)/?)?(?:([^/]+)/?)?'
const shareRegex = '(?:/s/([^/]+)/?)'
export const parseURL = memoize(pathname => {
  const newAction = pathname.startsWith('/new')
  const shareToken = get(pathname.match(shareRegex), [1], null)

  const match = pathname.match(regex)
  const preferencesVisible = pathname.startsWith('/preferences')

  return {
    logbookVisible: match ? !!match[1] : false,
    actionId: match ? match[2] : null,
    actionSlug: match ? match[3] : null,
    preferencesVisible,
    newAction,
    shareToken
  }
})

export const parseNewActionParameters = search => {
  const params = new URLSearchParams(search)
  const title = params.get('title')
  const due = params.get('due')
  const list = params.get('list')

  return {
    title,
    due,
    list
  }
}

export const sanitisePath = path => {
  const { actionId, actionSlug, shareToken } = parseURL(path)

  if (actionId) {
    // remove action ID and slug
    path = path
      .replace(actionId, 'action-id')
      .replace(actionSlug, 'action-slug')
  }

  if (shareToken) {
    path = path.replace(shareToken, 'sharetoken')
  }

  return path
}

export const sanitiseURL = fullpath => {
  const url = new URL(fullpath)

  url.pathname = sanitisePath(url.pathname)

  return url.toString()
}
