import React from 'react'
import PropTypes from 'prop-types'

export const NewActionIcon = ({ size, color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="3"
    {...props}
    role="presentation"
  >
    <line x1="10" y1="2" x2="10" y2="18" />
    <line x1="18" y1="10" x2="2" y2="10" />
  </svg>
)

NewActionIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

NewActionIcon.defaultProps = {
  size: '21',
  color: 'currentColor'
}

NewActionIcon.displayName = 'NewActionIcon'
