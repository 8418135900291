import React from 'react'
import PropTypes from 'prop-types'

export const DeleteActionIcon = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M6.86,24H17a3,3,0,0,0,3-3V5H4V21.14A2.86,2.86,0,0,0,6.86,24Z" />
    <path d="M19,1H15c0-.31-.42-1-1-1H10A1.2,1.2,0,0,0,9,1H5A1,1,0,0,0,4,2V4H20V2A1,1,0,0,0,19,1Z" />
  </svg>
)

DeleteActionIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

DeleteActionIcon.defaultProps = {
  size: '24',
  color: 'currentColor'
}

DeleteActionIcon.displayName = 'DeleteActionIcon'
