/* istanbul ignore file */
// taken from https://github.com/facebook/create-react-app/issues/5316
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  createContext,
  useContext
} from 'react'
import registerServiceWorker from './registerServiceWorker'

const hourly = 60 * 60 * 1000

const ServiceWorkerContext = createContext()

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(
    () => {
      savedCallback.current = callback
    },
    [callback]
  )

  // Set up the interval.
  useEffect(
    () => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    },
    [delay]
  )
}

function ServiceWorkerProvider(props) {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [assetsUpdateReady, setAssetsUpdateReady] = useState(false)
  const [assetsCached, setAssetsCached] = useState(false)

  useEffect(
    () => {
      const reloadOnActivateSW = event => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      }

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', reloadOnActivateSW)
        // ideally this should trigger page reload for multiple tabs
        // on service worker force update
        return () => {
          waitingServiceWorker.removeEventListener(
            'statechange',
            reloadOnActivateSW
          )
        }
      }
    },
    [waitingServiceWorker]
  )

  const value = useMemo(
    () => ({
      assetsUpdateReady,
      assetsCached,
      // Call when the user confirm update of application and reload page
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
      }
    }),
    [assetsUpdateReady, assetsCached, waitingServiceWorker]
  )

  // holds the service worker registration
  const reg = useRef()
  useInterval(async () => {
    // check for SW updates
    if (reg.current && reg.current.update) {
      console.log('checking for SW update…')
      try {
        await reg.current.update()
      } catch (error) {
        // ignore errors, e.g. InvalidStateError
        console.warn(error)
      }
    }
  }, hourly)

  // Once on component mounted subscribe to Update and Success events in
  // CRA's service worker wrapper
  useEffect(() => {
    registerServiceWorker({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting)
        setAssetsUpdateReady(true)
      },
      onSuccess: () => {
        setAssetsCached(true)
      },
      setRegistration: registration => {
        reg.current = registration
      }
    })
  }, [])

  return <ServiceWorkerContext.Provider value={value} {...props} />
}

function useServiceWorker() {
  const context = useContext(ServiceWorkerContext)

  if (!context) {
    throw new Error(
      'useServiceWorker must be used within a ServiceWorkerProvider'
    )
  }

  return context
}

export { ServiceWorkerProvider, useServiceWorker }
