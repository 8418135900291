import React from 'react'
import PropTypes from 'prop-types'

export const MenuIcon = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
  >
    <line x1="2" y1="2" x2="17" y2="2" />
    <line x1="2" y1="7" x2="17" y2="7" />
    <line x1="2" y1="12" x2="13" y2="12" />
    <line x1="2" y1="17" x2="17" y2="17" />
  </svg>
)

MenuIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

MenuIcon.defaultProps = {
  width: 19,
  height: 19,
  color: 'currentColor'
}
