import React from 'react'
import PropTypes from 'prop-types'

export const ScheduleIcon = ({ width, height, color, active }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill={color}
    fillOpacity={active ? 1 : 0.5}
  >
    <path d="M19.38,2H5.62c-2,0-3.62,1.62-3.62,3.62v14.76c0,2,1.62,3.62,3.62,3.62h13.76c2,0,3.62-1.62,3.62-3.62V5.62c0-2-1.62-3.62-3.62-3.62Zm2.62,5.86v12.49c0,1.46-1.06,2.65-2.37,2.65H5.37c-1.31,0-2.37-1.18-2.37-2.65V7.44c0-.79,.58-1.44,1.29-1.44H20.71c.71,0,1.29,.64,1.29,1.44v.43Z" />
    <circle cx="6.5" cy="14.5" r="1.5" />
    <circle cx="10.5" cy="14.5" r="1.5" />
    <circle cx="14.5" cy="14.5" r="1.5" />
    <circle cx="18.5" cy="14.5" r="1.5" />
    <circle cx="6.5" cy="18.5" r="1.5" />
    <circle cx="10.5" cy="18.5" r="1.5" />
    <circle cx="14.5" cy="18.5" r="1.5" />
    <circle cx="10.5" cy="10.5" r="1.5" />
    <circle cx="14.5" cy="10.5" r="1.5" />
    <circle cx="18.5" cy="10.5" r="1.5" />
  </svg>
)

ScheduleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  active: PropTypes.bool
}

ScheduleIcon.defaultProps = {
  width: '25',
  height: '25',
  color: 'currentColor',
  active: true
}
