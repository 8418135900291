import React from 'react'
import PropTypes from 'prop-types'

export const ThreeDotsIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={color}
    role="presentation"
  >
    <circle cx="10" cy="10" r="2.22" />
    <circle cx="15.97" cy="10" r="2.22" />
    <circle cx="4.03" cy="10" r="2.22" />
  </svg>
)

ThreeDotsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

ThreeDotsIcon.defaultProps = {
  size: '22',
  color: 'currentColor'
}

ThreeDotsIcon.displayName = 'ThreeDotsIcon'
