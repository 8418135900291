export const actionsPrefix = '@@actionsWeb'

// Drag-drop
export const REORDER_IN_SCHEDULE = `${actionsPrefix}/REORDER_IN_SCHEDULE`
export const REORDER_IN_LIST = `${actionsPrefix}/REORDER_IN_LIST`
export const MOVE_BETWEEN_LISTS = `${actionsPrefix}/MOVE_BETWEEN_LISTS`
export const MOVE_FROM_LIST_TO_SCHEDULE = `${actionsPrefix}/MOVE_FROM_LIST_TO_SCHEDULE`

// Drag-drop byproducts
export const REINDEX_SCHEDULE = `${actionsPrefix}/REINDEX_SCHEDULE`
export const REORDER_COMPLETE = `${actionsPrefix}/REORDER_COMPLETE`
export const REINDEX_LIST = `${actionsPrefix}/REINDEX_LIST`

// Actions updates
export const CREATE_ACTION_IN_LIST = `${actionsPrefix}/CREATE_ACTION_IN_LIST`
export const CREATE_ACTION_IN_SCHEDULE = `${actionsPrefix}/CREATE_ACTION_IN_SCHEDULE`
export const UPDATE_ACTION = `${actionsPrefix}/UPDATE_ACTION`
export const TOGGLE_COMPLETE_ACTION = `${actionsPrefix}/TOGGLE_COMPLETE_ACTION`
export const DELETE_ACTION_START = `${actionsPrefix}/DELETE_ACTION_START`
export const DELETE_ACTION_FOR_REAL = `${actionsPrefix}/DELETE_ACTION_FOR_REAL`
export const DELETE_ACTION_CANCEL = `${actionsPrefix}/DELETE_ACTION_CANCEL`
export const UNDELETE_ACTION = `${actionsPrefix}/UNDELETE_ACTION`
export const CHANGE_ACTION_TITLE = `${actionsPrefix}/CHANGE_ACTION_TITLE`
export const CHANGE_ACTION_NOTES = `${actionsPrefix}/CHANGE_ACTION_NOTES`
export const CHANGE_ACTION_DATE = `${actionsPrefix}/CHANGE_ACTION_DATE`
export const CHANGE_ACTION_DEADLINE = `${actionsPrefix}/CHANGE_ACTION_DEADLINE`
export const CHANGE_ACTION_REPEAT = `${actionsPrefix}/CHANGE_ACTION_REPEAT`
export const CHANGE_ACTION_REMINDER = `${actionsPrefix}/CHANGE_ACTION_REMINDER`
export const CHANGE_ACTION_PRIORITY = `${actionsPrefix}/CHANGE_ACTION_PRIORITY`

// List updates
export const CREATE_LIST = `${actionsPrefix}/CREATE_LIST`
export const UPDATE_LIST = `${actionsPrefix}/UPDATE_LIST`
export const DELETE_LIST_START = `${actionsPrefix}/DELETE_LIST_START`
export const DELETE_LIST_FOR_REAL = `${actionsPrefix}/DELETE_LIST_FOR_REAL`
export const UNDELETE_LIST = `${actionsPrefix}/UNDELETE_LIST`
export const LIST_CHANGE_THEME = `${actionsPrefix}/LIST_CHANGE_THEME`
export const LIST_CHANGE_TITLE = `${actionsPrefix}/LIST_CHANGE_TITLE`
export const LIST_CHANGE_ARCHIVED = `${actionsPrefix}/LIST_CHANGE_ARCHIVED`
export const TOGGLE_LIST_NOTIFICATION_SETTING = `${actionsPrefix}/TOGGLE_LIST_NOTIFICATION_SETTING`

// Subtasks
export const TOGGLE_COMPLETE_SUBTASK = `${actionsPrefix}/TOGGLE_COMPLETE_SUBTASK`
export const CHANGE_SUBTASK_TITLE = `${actionsPrefix}/CHANGE_SUBTASK_TITLE`
export const DELETE_SUBTASK = `${actionsPrefix}/DELETE_SUBTASK`
export const DELETE_SUBTASK_FOR_REAL = `${actionsPrefix}/DELETE_SUBTASK_FOR_REAL`
export const CREATE_SUBTASK = `${actionsPrefix}/CREATE_SUBTASK`
export const UPDATE_SUBTASK = `${actionsPrefix}/UPDATE_SUBTASK`
export const REORDER_SUBTASK = `${actionsPrefix}/REORDER_SUBTASK`
export const CLONE_SUBTASKS = `${actionsPrefix}/CLONE_SUBTASKS`

// General
export const UPDATE_FROM_FIREBASE = `${actionsPrefix}/UPDATE_FROM_FIREBASE`
export const FIREBASE_ERROR = `${actionsPrefix}/FIREBASE_ERROR`
export const FULLY_LOADED = `${actionsPrefix}/FULLY_LOADED`
export const SET_TODAY = `${actionsPrefix}/SET_TODAY`
export const CHANGE_PREFERENCE = `${actionsPrefix}/CHANGE_PREFERENCE`
export const CHANGE_USER_SETTING = `${actionsPrefix}/CHANGE_USER_SETTING`
export const CLEAR_RECENTLY_DELETED_START = `${actionsPrefix}/CLEAR_RECENTLY_DELETED_START`
export const CLEAR_RECENTLY_DELETED_END = `${actionsPrefix}/CLEAR_RECENTLY_DELETED_END`

// Location updates
export const UPDATE_LOCATION = `${actionsPrefix}/UPDATE_LOCATION`
export const INIT_LOCATION = `${actionsPrefix}/INIT_LOCATION`

// Events
export const ADD_GOOGLE_ACCOUNT = `${actionsPrefix}/ADD_GOOGLE_ACCOUNT`
export const REMOVE_GOOGLE_ACCOUNT = `${actionsPrefix}/REMOVE_GOOGLE_ACCOUNT`
export const STORE_GOOGLE_EVENTS = `${actionsPrefix}/STORE_GOOGLE_EVENTS`
export const TOGGLE_GOOGLE_CALENDAR = `${actionsPrefix}/TOGGLE_GOOGLE_CALENDAR`

// Sharing
export const SHARE_LIST = `${actionsPrefix}/SHARE_LIST`
export const UNSHARE_LIST = `${actionsPrefix}/UNSHARE_LIST`
export const REMOVE_LIST_USER = `${actionsPrefix}/REMOVE_LIST_USER`
export const ACCEPT_INVITE = `${actionsPrefix}/ACCEPT_INVITE`
export const GET_SHARING_PREVIEW = `${actionsPrefix}/GET_SHARING_PREVIEW`
export const STORE_SHARING_PREVIEW = `${actionsPrefix}/STORE_SHARING_PREVIEW`
export const CHANGE_SHARING_NAME = `${actionsPrefix}/CHANGE_SHARING_NAME`
