import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Flex, Absolute } from 'rebass'
import { NewActionIcon, ChangeColorIcon } from './icons'
import { CircularButton } from './CircularButton'
import posed from 'react-pose'
import { listMainButtonPose } from './buttonPoses'
import { ThreeDotsIcon } from './icons/ThreeDots'

const ListChooseColorButton = props => (
  <CircularButton
    aria-label="Choose colour"
    data-tip="Choose list colour"
    data-delay-show={300}
    {...props}
  >
    <ChangeColorIcon />
  </CircularButton>
)

const ListSettingsButton = props => (
  <CircularButton
    aria-label="List settings"
    data-tip="List settings"
    data-delay-show={300}
    {...props}
  >
    <ThreeDotsIcon size={26} />
  </CircularButton>
)

const ListAddActionButton = forwardRef(({ color, ...props }, ref) => (
  <CircularButton
    aria-label="Create Action"
    data-tip="Create Action<br/><small>(hold shift to create at top)<small/>"
    data-html={true}
    data-delay-show={500}
    width={100}
    color={color}
    borderRadius={6} // same as Action cards
    {...props}
    ref={ref}
  >
    <NewActionIcon color={color} />
  </CircularButton>
))

export const PosedListAddActionButton = posed(ListAddActionButton)(
  listMainButtonPose
)

export const ListButtons = React.memo(
  ({
    color,
    bg,
    onAddButtonClick,
    onSettingsButtonClick,
    onChooseColorButtonClick,
    newActionIsVisible
  }) => (
    <Absolute bottom={0} left={0} right={0} pb={45}>
      <Flex px={4} justifyContent="space-between">
        <ListSettingsButton
          color={color}
          bg={bg}
          onClick={onSettingsButtonClick}
        />
        <PosedListAddActionButton
          color={color}
          bg={bg}
          onClick={onAddButtonClick}
          pose={newActionIsVisible ? 'pressed' : 'init'}
          className={null} // don't auto-hide on lists
        />
        <ListChooseColorButton
          color={color}
          bg={bg}
          onClick={onChooseColorButtonClick}
        />
      </Flex>
    </Absolute>
  )
)

ListButtons.propTypes = {
  bg: PropTypes.string,
  onChooseColorButtonClick: PropTypes.func,
  onAddButtonClick: PropTypes.func
}

ListButtons.displayName = 'ListButtons'
