import React from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'

const uncheckTransition = {
  pathLength: {
    ease: 'easeOut',
    duration: 150
  }
}

const checkTransition = {
  pathLength: {
    ease: 'easeOut',
    duration: 150
  }
}

const PosedPath = posed.path({
  initial: {
    pathLength: 1,
    applyAtStart: {
      opacity: 1
    },
    transition: uncheckTransition
  },
  checked: {
    applyAtEnd: {
      opacity: 0
    },
    pathLength: 0,
    transition: checkTransition
  }
})

export const FatTick = React.forwardRef(
  ({ size, color, checked, strokeWidth = 3, ...props }, ref) => (
    <svg
      viewBox="0 0 49.5 39.18"
      width={size}
      height={size}
      ref={ref}
      {...props}
    >
      {/* mask for tick stroke animation */}
      <defs>
        <clipPath id="tickClip">
          <path
            d="M45.34,3.78a5.43,5.43,0,0,0-7.4.4L18.48,23.65a.47.47,0,0,1-.66,0l-6.17-6.18a5.44,5.44,0,0,0-7.4-.4,5.22,5.22,0,0,0-.2,7.58l6.71,6.71,5,5a3.39,3.39,0,0,0,4.8,0l5-5,20-20A5.22,5.22,0,0,0,45.34,3.78Z"
            fill="white"
          />
        </clipPath>
      </defs>

      {/* masked tick animation */}
      <PosedPath
        pose={checked ? 'checked' : 'initial'}
        withParent={false}
        // two directions:
        // d="M4.05,17.26,14.14,27.32a5.66,5.66,0,0,0,8,0L45.53,4"
        d="M45.5,4L22.1,27.3c-2.2,2.2-5.8,2.2-8,0L4.1,17.3"
        stroke={color}
        strokeLinecap="rounded"
        strokeWidth="18"
        clipPath="url(#tickClip)"
        style={{ WebkitClipPath: 'url(#tickClip)' }}
        fill="none"
      />

      {/* tick outline */}
      {/* clipped by path too, so 4px stroke = 2px stroke inside only */}
      <path
        d="M45.34,3.78a5.43,5.43,0,0,0-7.4.4L18.48,23.65a.47.47,0,0,1-.66,0l-6.17-6.18a5.44,5.44,0,0,0-7.4-.4,5.22,5.22,0,0,0-.2,7.58l6.71,6.71,5,5a3.39,3.39,0,0,0,4.8,0l5-5,20-20A5.22,5.22,0,0,0,45.34,3.78Z"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        clipPath="url(#tickClip)"
        style={{ WebkitClipPath: 'url(#tickClip)' }}
      />
    </svg>
  )
)

FatTick.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

FatTick.defaultProps = {
  size: '28',
  color: 'currentColor'
}
