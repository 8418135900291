import React from 'react'
import system from '@rebass/components'
import { Link as BaseLink } from '@reach/router'
import { omit } from 'lodash'
import { Link as RebassLink } from 'rebass'

const omitlist = [
  'css',
  'is',
  'isActive',
  'getProps',
  'exact',
  'color',
  'bg',
  'm',
  'mx',
  'my',
  'mt',
  'mb',
  'mr',
  'ml',
  'p',
  'px',
  'py',
  'flex'
]

const CleanRouterLink = React.forwardRef((props, ref) => {
  const cleanprops = omit(props, omitlist)
  return <BaseLink {...cleanprops} ref={ref} />
})

export const Link = system(
  { extend: CleanRouterLink },
  { textDecoration: 'none' },
  'color',
  'space'
)

const CleanExternalLinkInner = React.forwardRef((props, ref) => {
  const cleanprops = omit(props, omitlist)
  return <RebassLink {...cleanprops} ref={ref} />
})

export const CleanExternalLink = system(
  { extend: CleanExternalLinkInner },
  { textDecoration: 'none' },
  'color',
  'space'
)

export const LinkWithCircle = ({ color, size, css = {}, ...rest }) => (
  <Link
    to="../"
    color={color}
    role="button"
    css={{
      borderRadius: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid transparent`,
      svg: { display: 'block' },
      '&:focus': {
        outline: 'none'
      },
      '&:focus-visible': {
        border: `1px solid ${color}`
      },
      ...css
    }}
    {...rest}
  />
)

export const LinkInRoundedFocusBox = props => (
  <LinkWithCircle {...props} css={{ borderRadius: '5px' }} />
)

export const IconLink = React.memo(({ isActive, icon: Icon, ...rest }) => (
  <LinkInRoundedFocusBox
    p={0}
    m={0}
    {...rest}
    children={<Icon active={!isActive} />}
  />
))
