import React from 'react'
import ReactDOM from 'react-dom'
import loadPolyfills from './loadPolyfills'
import App from './App'

import { branch } from './config'

// Redirect to Actions
if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname !== 'actions.moleskinestudio.com' &&
  window.location.hostname !== 'actions-staging.moleskinestudio.com' &&
  // don't redirect if this is a branch build
  (branch == null || branch === '')
) {
  window.location.replace('https://actions.moleskinestudio.com')
} else {
  loadPolyfills().then(() =>
    ReactDOM.render(<App />, document.getElementById('root'))
  )
}
