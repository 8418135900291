import React from 'react'
import PropTypes from 'prop-types'

export const UndoCircle = React.forwardRef(({ width, height, color }, ref) => (
  <svg width={width} height={height} viewBox="0 0 30 30">
    <path
      ref={ref}
      d="M15,.5
      A 14.5,14.5, 0,0,1, 29.5,15
      14.5,14.5, 0,1,1, 15,.5
      Z"
      stroke={color}
      fill="none"
    />
  </svg>
))

UndoCircle.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

UndoCircle.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor'
}
