import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { deletingActionsSelector } from '../actions/selectors'
import { cancelDeleteAction } from '../actions'
import { UndoButton, UndoButtonWrapper } from './ui/UndoButton'

export const DeleteUndoInner = ({ actions, cancelDeleteAction, darkMode }) => {
  useEffect(() => ReactTooltip.rebuild(), [actions.length])
  return (
    <UndoButtonWrapper>
      {map(actions, ([actionId, action]) => (
        <UndoButton
          key={actionId}
          title={action.title}
          actionId={actionId}
          onClick={() => cancelDeleteAction({ actionId })}
          darkMode={darkMode}
        />
      ))}
    </UndoButtonWrapper>
  )
}
const mapStateToProps = state => ({
  actions: deletingActionsSelector(state)
})

export default connect(
  mapStateToProps,
  { cancelDeleteAction }
)(DeleteUndoInner)
