export const themes = {
  White: {
    bg: '#FFFFFF',
    text: '#000000',
    category: 'mono'
  },
  'Polar White': {
    bg: '#d7d7d7',
    text: '#000000',
    category: 'mono'
  },
  'Corellian Grey': {
    bg: '#a6a6a6',
    text: '#000000',
    category: 'mono'
  },
  'Steel Grey': {
    bg: '#707070',
    text: '#FFFFFF',
    category: 'mono'
  },
  'Hawking Black': {
    bg: '#000000',
    text: '#FFFFFF',
    category: 'mono'
  },
  'Brilliant Violet': {
    bg: '#483C9A',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Royal Blue': {
    bg: '#19579d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Antwerp Blue': {
    bg: '#1574B9',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Sky Blue': {
    bg: '#4e9bc1',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Underwater Blue': {
    bg: '#1C95A0',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Bonobo Green': {
    bg: '#1a9b7f',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Oxide Green': {
    bg: '#168758',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Kiwi Green': {
    bg: '#7b952a',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Sunflower Yellow': {
    bg: '#cf9f34',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Orange Yellow': {
    bg: '#E78E2B',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Cadmium Orange': {
    bg: '#db4319',
    text: '#FFFFFF',
    category: 'vivid'
  },
  Red: {
    bg: '#bb211d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Scarlet Red': {
    bg: '#a2152d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Fluorescent Pink': {
    bg: '#c41751',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Wednesday Pink': {
    bg: '#bd5da8',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Mauve Purple': {
    bg: '#9C2B78',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Lilac Violet': {
    bg: '#9895c7',
    text: '#000000',
    category: 'pastel'
  },
  'Tropical Blue': {
    bg: '#70afd3',
    text: '#000000',
    category: 'pastel'
  },
  'Voyager Blue': {
    bg: '#7ac4d3',
    text: '#000000',
    category: 'pastel'
  },
  'Pale Turquoise Blue': {
    bg: '#99c6d4',
    text: '#000000',
    category: 'pastel'
  },
  'Cirrus Blue Grey': {
    bg: '#a3b9c0',
    text: '#000000',
    category: 'pastel'
  },
  'Tea Green': {
    bg: '#9acfbf',
    text: '#000000',
    category: 'pastel'
  },
  'Citrus Yellow': {
    bg: '#dacd8e',
    text: '#000000',
    category: 'pastel'
  },
  'Solar Yellow': {
    bg: '#eac285',
    text: '#000000',
    category: 'pastel'
  },
  'Fawn Grey': {
    bg: '#c8c2ac',
    text: '#000000',
    category: 'pastel'
  },
  'Pale Gold Yellow': {
    bg: '#d5bc98',
    text: '#000000',
    category: 'pastel'
  },
  'Peach Orange': {
    bg: '#fba592',
    text: '#000000',
    category: 'pastel'
  },
  'Pastel Red': {
    bg: '#e4a5a9',
    text: '#000000',
    category: 'pastel'
  },
  'Flamingo Pink': {
    bg: '#e8aed1',
    text: '#000000',
    category: 'pastel'
  },
  'Pale Grape Purple': {
    bg: '#b489b0',
    text: '#000000',
    category: 'pastel'
  },
  'Washed Indigo': {
    bg: '#504c70',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Azimuth Blue': {
    bg: '#2c4772',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Steel Blue': {
    bg: '#436185',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Arctic Blue': {
    bg: '#4f6883',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Summer Blue': {
    bg: '#4f7e93',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Seaweed Green': {
    bg: '#558687',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Pistachio Green': {
    bg: '#637f59',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Bonobo Grey': {
    bg: '#2a454c',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Mint Green': {
    bg: '#42644f',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Eucalyptus Green': {
    bg: '#676d4b',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Pebble Grey': {
    bg: '#919086',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Espresso Brown': {
    bg: '#7f765d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Kraft Brown': {
    bg: '#ac8c65',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Autumnal Orange': {
    bg: '#8a4032',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Brick Red': {
    bg: '#752f2e',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Shiraz Red': {
    bg: '#5b242d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Dusty Pink': {
    bg: '#774855',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Peach Blossom Pink': {
    bg: '#96636a',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Pale Lavender Pink': {
    bg: '#8c7285',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Muted Mauve': {
    bg: '#68445d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Horizon Grey': {
    bg: '#67606d',
    text: '#FFFFFF',
    category: 'vivid'
  },
  'Dark Indigo': {
    bg: '#211337',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Midnight Blue': {
    bg: '#0f193b',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Sapphire Blue': {
    bg: '#10264b',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Kingfisher Blue': {
    bg: '#00374d',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Pine Grey': {
    bg: '#10262e',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Lotus Green': {
    bg: '#112d20',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Forest Green': {
    bg: '#1f3509',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Mustard Yellow': {
    bg: '#6c4b00',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Amber Orange': {
    bg: '#5f210d',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Crimson Red': {
    bg: '#560d06',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Burgundy Red': {
    bg: '#460d1c',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Plum Purple': {
    bg: '#43152f',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Deep Purple': {
    bg: '#3a1436',
    text: '#FFFFFF',
    category: 'dark'
  },
  'Luna Black': {
    bg: '#191e22',
    text: '#FFFFFF',
    category: 'dark'
  }
}
export const defaultLightTheme = themes.White
export const defaultDarkTheme = {
  bg: '#24282E',
  text: '#FFFFFF'
}

export const darkBackgroundColor = '#000000'
export const lightBackgroundColor = '#f4f4f4'
export const getBGColor = darkMode =>
  darkMode ? darkBackgroundColor : lightBackgroundColor

export const themeNameMapping = {
  'Brilliant Violet Pastel': 'Lilac Violet',
  'Antwerp Blue Pastel': 'Tropical Blue',
  'Sky Blue Pastel': 'Voyager Blue',
  'Arctic Blue Pastel': 'Pale Turquoise Blue',
  'Underwater Blue Pastel': 'Cirrus Blue Grey',
  'Bonobo Green Pastel': 'Tea Green',
  'Sunflower Yellow Pastel': 'Citrus Yellow',
  'Orange Yellow Pastel': 'Solar Yellow',
  'Pebble Grey Pastel': 'Fawn Grey',
  'Kraft Brown Pastel': 'Pale Gold Yellow',
  'Cadmium Orange Pastel': 'Peach Orange',
  'Red Pastel': 'Pastel Red',
  'Wednesday Pink Pastel': 'Flamingo Pink',
  'Mauve Purple Pastel': 'Pale Grape Purple',
  'Muted Brilliant Violet': 'Washed Indigo',
  'Muted Royal Blue': 'Azimuth Blue',
  'Muted Antwerp Blue': 'Steel Blue',
  'Muted Sky Blue': 'Summer Blue',
  'Muted Bonobo Green': 'Pistachio Green',
  'Muted Oxide Green': 'Mint Green',
  'Muted Kiwi Green': 'Eucalyptus Green',
  'Muted Sunflower Yellow': 'Espresso Brown',
  'Muted Cadmium Orange': 'Autumnal Orange',
  'Muted Red': 'Brick Red',
  'Muted Scarlet Red': 'Shiraz Red',
  'Muted Fluorescent Pink': 'Dusty Pink',
  'Muted Wednesday Pink': 'Pale Lavender Pink',
  'Muted Mauve Purple': 'Muted Mauve',
  'Muted Lilac': 'Horizon Grey',
  'Dark Brilliant Violet': 'Dark Indigo',
  'Dark Royal Blue': 'Midnight Blue',
  'Dark Antwerp Blue': 'Sapphire Blue',
  'Dark Sky Blue': 'Kingfisher Blue',
  'Dark Underwater Blue': 'Pine Grey',
  'Dark Bonobo Green': 'Lotus Green',
  'Dark Kiwi Green': 'Forest Green',
  'Dark Orange Yellow': 'Mustard Yellow',
  'Dark Cadmium Orange': 'Amber Orange',
  'Dark Red': 'Crimson Red',
  'Dark Scarlet Red': 'Burgundy Red',
  'Dark Fluorescent Pink': 'Plum Purple',
  'Dark Mauve Purple': 'Deep Purple'
}

// import { get, mapKeys } from 'lodash'
// const newThemes = mapKeys(themes, (v, k) => get(themeNameMapping, k, k))
// console.log(newThemes)
