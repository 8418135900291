import React, { forwardRef } from 'react'
import styled from 'styled-components'

const buttonSize = 19
const borderWidth = 2
const spacing = 2
const innerSize = buttonSize - spacing * 2 - borderWidth * 2

const Border = styled.button`
  background-color: transparent;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  padding: ${spacing}px;
  margin: 0;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  user-select: none;
  border: ${borderWidth}px solid white;
  position: relative;
  ${({ pointerEvents }) =>
    pointerEvents ? `pointer-events: ${pointerEvents}` : null};

  &:focus {
    outline: none;
  }
  &:focus-visible {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      /* -7px aligns focus border with menu icon */
      margin: -7px;
      border: 1px solid white;
      border-radius: 5px;
    }
  }

  &:hover {
    .circle {
      border-width: 3px;
    }
  }
  &:active {
    .mask {
      transform: rotate(${({ enabled }) => (enabled ? '-45deg' : '225deg')});
      transition-duration: 2s;
      transition-timing-function: ease-out;
    }
  }
`

const Mask = styled.div`
  height: ${innerSize}px;
  width: ${innerSize / 2}px;
  margin-right: ${innerSize / 2}px;
  overflow: hidden;
  transform-origin: right center;
  transition: 0.5s ease-in-out transform;
  transform: rotate(${({ enabled }) => (enabled ? '0deg' : '180deg')});
`

const Circle = styled.div`
  height: ${innerSize}px;
  width: ${innerSize}px;
  border-radius: 50%;
  border: ${innerSize / 2}px solid white;
  transition: 0.2s ease border-width;
`

export const DarkModeButton = forwardRef(
  ({ onClick, enabled = false, ...rest }, ref) => (
    <Border
      // transition: 'background-color 0.2s ease',
      // svg: { transition: 'all 0.2s ease', transform: 'scale(1)' },
      // '&:focus': {
      //   border: `1px solid ${transparentize(0.2, color)}`,
      //   outline: 'none'
      // },
      // '&:hover': {
      //   svg: { transform: `scale(${20 / 24})` },
      //   backgroundColor: transparent ? bg : lighten(0.03, bg)
      // },
      // '&:active': {
      //   backgroundColor: transparent ? bg : lighten(0.05, bg)
      // }

      onClick={onClick}
      ref={ref}
      enabled={enabled}
      {...rest}
    >
      <Mask className="mask" enabled={enabled}>
        <Circle className="circle" />
      </Mask>
    </Border>
  )
)

DarkModeButton.displayName = 'DarkModeButton'
