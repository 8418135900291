import React, { forwardRef } from 'react'
import posed from 'react-pose'
import { Flex } from 'rebass'
import { connect } from 'react-redux'

import { LinkWithCircle } from './ui/Link'
import { MenuIcon } from './ui/icons'
import DarkModeButton from './DarkModeButton'
import { FlexGrow } from './ui/Flex'
import AppUpdateIcon from './AppUpdateIcon'
import { darkBackgroundColor } from '../constants/actionsThemeColours'

export const navHeight = 40
export const navHeightPx = `${navHeight}px`

const IconLink = React.memo(({ isActive, icon: Icon, ...rest }) => (
  <LinkWithCircle
    p={0}
    m={0}
    {...rest}
    children={<Icon filled={!isActive} />}
  />
))

const LinkContainerInner = forwardRef((props, ref) => (
  <Flex
    is="nav"
    role="navigation"
    bg="black"
    color="white"
    ref={ref}
    css={{ height: navHeight, borderBottom: '1px solid #262626' }}
  >
    <Flex mx={4} width={1} alignItems="center" {...props} />
  </Flex>
))

const LinkContainer = posed(LinkContainerInner)({
  init: {
    backgroundColor: '#000000'
  },
  preferences: {
    backgroundColor: darkBackgroundColor,
    transition: {
      backgroundColor: {
        delay: 100
      }
    }
  }
})

const PreferencesLink = ({ preferencesVisible }) => (
  <IconLink
    to="/preferences"
    isActive={preferencesVisible}
    icon={MenuIcon}
    aria-label="Open Preferences Menu"
    css={{
      borderRadius: '5px',
      ...(preferencesVisible ? { pointerEvents: 'none' } : {})
    }}
    color="white"
    p={1}
    mr={2}
  />
)

const TopNavInner = ({ preferencesVisible }) => (
  <LinkContainer pose={preferencesVisible ? 'preferences' : 'init'}>
    <PreferencesLink preferencesVisible={preferencesVisible} />
    <DarkModeButton pointerEvents={preferencesVisible ? 'none' : null} />
    <FlexGrow />
    <AppUpdateIcon />
  </LinkContainer>
)

export const TopNav = connect(state => ({
  preferencesVisible: state.location.preferencesVisible
}))(TopNavInner)
