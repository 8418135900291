import React from 'react'
import PropTypes from 'prop-types'

export const LogbookIcon = ({ width, height, color, active }) => (
  <svg width={width} height={height} viewBox="0 0 18 25">
    <g fill={color} fillOpacity={active ? 1 : 0.5}>
      <rect x="13" width="2" height="25" rx="1" ry="1" />
      <path d="M16,1h.25c.97,0,1.75,.78,1.75,1.75V19.25c0,.97-.78,1.75-1.75,1.75h-.25V1h0Z" />
      <path d="M1.66,21H12V1H1.75c-.97,0-1.75,.78-1.75,1.75V22.25c0,.97,.78,1.75,1.75,1.75H12v-1H1.75c-.41,0-.75-.34-.75-.75v-.59c0-.36,.29-.66,.66-.66Z" />
      <path d="M16,23h1.5c.28,0,.5,.22,.5,.5h0c0,.28-.22,.5-.5,.5h-1.5v-1h0Z" />
    </g>
  </svg>
)

LogbookIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  active: PropTypes.bool
}

LogbookIcon.defaultProps = {
  width: 18,
  height: 25,
  color: 'currentColor',
  active: true
}
