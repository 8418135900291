import React, { useState, useRef, useEffect } from 'react'
import { Heading, Absolute, Flex, Text, Box } from 'rebass'
import { rem } from 'polished'
import styled from 'styled-components'
import {
  space,
  fontSize,
  fontWeight,
  color,
  background,
  themeGet
} from 'styled-system'
import { transparentize } from 'polished'
import { padding } from './List'
import { Avatar } from './Avatar'

const headingFontProperties = {
  fontSize: rem(28),
  fontWeight: 700
}

const Title = React.forwardRef(({ title, color, ...props }, ref) => (
  <Heading
    is="h3"
    {...headingFontProperties}
    p={2}
    ml={-9}
    tabIndex={0}
    color={color}
    css={{
      textAlign: 'left',
      lineHeight: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      minWidth: '100px',
      minHeight: '1em',
      transition: 'opacity 0.2s ease',
      // so title doesn't move when it's focused
      border: `1px solid transparent`,
      // letterSpacing: '0.05em',
      '&:hover': {
        opacity: 0.8
      },
      '&:focus': {
        outline: 'none'
      },
      '&:focus-visible': {
        border: `1px solid ${color}`,
        borderRadius: '4px'
      }
    }}
    onMouseDown={e => e.preventDefault()} // prevent focus after click
    role="button"
    aria-label={`Edit List ${title}`}
    ref={ref}
    {...props}
  >
    {title}
  </Heading>
))

const inputWithNoStyleProps = React.forwardRef(
  ({ fontWeight, fontSize, color, px, py, bg, mb, mx, ...props }, ref) => (
    <input {...props} ref={ref} />
  )
)

const StyledInput = styled(inputWithNoStyleProps)`
  // force inherit body font family
  font-family: inherit;
  ${fontSize};
  ${fontWeight};
  ${space};
  ${color};
  ${background};
  /* width: 100%; */
  border-radius: 6px;
  border: none;
  /* to allow for 1px focus border */
  &:focus {
    /* We're using background colour to emphasise focus */
    outline: none;
  }
  &::placeholder {
    color: ${props =>
      transparentize(
        0.6,
        themeGet('colors.' + props.color, props.color)(props)
      )};
  }
`

const EditableTitle = React.forwardRef(({ title, color, ...props }, ref) => (
  <StyledInput
    px="10px"
    py="6px"
    mx="-10px"
    mb="1px"
    {...headingFontProperties}
    color={color}
    bg={transparentize(0.9, color)}
    defaultValue={title}
    ref={ref}
    {...props}
  />
))

export const ListHeading = ({
  title,
  bg,
  color,
  onChangeTitle,
  sharedWith,
  placeholder = null,
  creatingNewTitle = false,
  inSettingsMode = null
}) => {
  const [isEditing, setEditing] = useState(creatingNewTitle)
  const inputRef = useRef()
  const titleRef = useRef()

  useEffect(
    () => {
      if (isEditing !== inSettingsMode && inSettingsMode != null) {
        setEditing(inSettingsMode)
      }
    },
    // eslint-disable-next-line
    [inSettingsMode]
  )

  useEffect(
    () => {
      if (isEditing) {
        inputRef.current.focus()
      } else {
        titleRef.current.focus()
      }
    },
    [isEditing]
  )

  const getValue = () => inputRef.current.value.trim()

  const isEmpty = () => {
    return getValue().length === 0
  }

  const saveEdits = () => {
    const newTitle = getValue()

    if (isEmpty()) {
      // Can't save an empty title
      return
    }

    if (creatingNewTitle) {
      onChangeTitle(newTitle)
      return
    }

    if (!inSettingsMode) {
      setEditing(false)
    }

    if (newTitle && newTitle !== title) {
      // don't save empty title or if unchanged
      onChangeTitle(newTitle)
    }
  }

  const cancel = () => {
    if (creatingNewTitle) {
      onChangeTitle(null)
      return
    }

    setEditing(false)
  }

  return (
    <Absolute top={0} left={0} right={0}>
      <Flex py={40} px={padding} flexDirection="column">
        {isEditing ? (
          <EditableTitle
            title={title}
            ref={inputRef}
            color={color}
            onBlur={saveEdits}
            placeholder={placeholder}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                // press enter
                e.preventDefault()
                saveEdits()
              } else if (e.keyCode === 27) {
                // press escape
                cancel()
              }
              // Prevent keys going up to Action
              // otherwise typing c would complete the Action
              e.stopPropagation()
            }}
          />
        ) : (
          <Title
            color={color}
            title={title}
            ref={titleRef}
            onClick={() => setEditing(true)}
            onKeyDown={e => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                // press enter or space
                e.preventDefault()
                setEditing(true)
              }
            }}
          />
        )}
        {sharedWith && !inSettingsMode ? (
          <Flex css={{ gap: 4 }}>
            {Array.isArray(sharedWith) ? (
              sharedWith.map(({ initials, name, userId }) => (
                <Avatar
                  bg={bg}
                  color={color}
                  size={24}
                  fontSize={rem(10)}
                  key={`${title}__share__${userId}`}
                  title={name}
                >
                  <Box as="span" css={{ userSelect: 'none' }}>
                    {initials}
                  </Box>
                </Avatar>
              ))
            ) : (
              <Text fontSize={1}>{sharedWith}</Text>
            )}
          </Flex>
        ) : null}
      </Flex>
    </Absolute>
  )
}
