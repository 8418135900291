import moment from 'moment'
import { call, fork, takeEvery, take, race, delay } from 'redux-saga/effects'
import { DELETE_ACTION_START, DELETE_ACTION_CANCEL } from '../actions/types'
import {
  softDeleteAction,
  updateActionLocallyThenRemotely
} from './actionUpdates'
import { trackEventAsync } from '../analytics'

export const deletionTime = 10000 // 10 seconds
const getDeletionTime = () =>
  moment()
    .add(deletionTime, 'milliseconds')
    .toDate()

export function* deleteAction(api, { data: { actionId, isCompleted } }) {
  // Step 1 - mark as deleted
  const update = {
    markedForDeletion: true,
    markedForDeletionAt: getDeletionTime()
  }
  yield call(updateActionLocallyThenRemotely, api, actionId, update)

  // Step 2 - wait for 10 seconds
  const { cancel, timeout } = yield race({
    cancel: take(
      action =>
        // Filter to just cancel, and just this Action
        action.type === DELETE_ACTION_CANCEL &&
        action.data.actionId === actionId
    ),
    timeout: delay(deletionTime)
  })

  if (timeout) {
    // Step 3 - if not cancelled do deletion
    yield call(softDeleteAction, api, actionId)
    yield fork(trackEventAsync, 'Action Deleted')
  } else if (cancel) {
    // Un-mark as deleted
    const update = {
      markedForDeletion: false,
      markedForDeletionAt: api.delete()
    }
    yield call(updateActionLocallyThenRemotely, api, actionId, update)
    yield fork(trackEventAsync, 'Action Deletion Cancelled')
  }
}

export function* watchDeleteAction(api) {
  yield takeEvery(DELETE_ACTION_START, deleteAction, api)
}
