import React from 'react'
import PropTypes from 'prop-types'

export const DeleteListIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={color}
    role="presentation"
  >
    <path d="M3,18a1.78,1.78,0,0,0,2,2H15a1.77,1.77,0,0,0,2-2V5H3Z" />
    <path d="M16,1H12a1.11,1.11,0,0,0-1-1H9A1.12,1.12,0,0,0,8,1H4A1.09,1.09,0,0,0,3,2V4H17V2A1.09,1.09,0,0,0,16,1Z" />
  </svg>
)

DeleteListIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

DeleteListIcon.defaultProps = {
  size: '20',
  color: 'currentColor'
}

DeleteListIcon.displayName = 'DeleteListIcon'
