import React, { lazy, Suspense } from 'react'
import { Home } from './Home'

import { ServiceWorkerProvider } from './serviceWorker'
import { ThemeProvider } from '@bonobolabs/mds-web-ui-reach'
import { GlobalStyles } from './components/ui/Layout'
import ErrorBoundary from './ErrorBoundary'

import { init as initSentry } from './Sentry'
import { initPerformanceMonitoring } from './perf'

const ReduxProvider = lazy(() =>
  import(/* webpackChunkName: "redux" */ './redux')
)

initSentry()
initPerformanceMonitoring()

export const App = () => (
  <ServiceWorkerProvider>
    <ThemeProvider>
      <>
        <GlobalStyles />
        <Suspense fallback={<div />}>
          <ReduxProvider>
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          </ReduxProvider>
        </Suspense>
      </>
    </ThemeProvider>
  </ServiceWorkerProvider>
)
export default App
