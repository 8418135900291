const scale = {
  type: 'spring',
  stiffness: 700,
  damping: 25,
  mass: 1
}

// list add button
// list settings done button
export const listMainButtonPose = {
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    y: 0,
    transition: {
      scale: {
        type: 'tween',
        duration: 150
      },
      y: {
        type: 'tween',
        duration: 150
      }
    }
  },
  pressed: {
    y: -30,
    scale: 0,
    transition: {
      scale: {
        type: 'tween',
        duration: 150
      }
    }
  },
  hover: {
    scale: 1.05,
    y: 0,
    transition: {
      scale
    }
  },
  press: {
    scale: 0.85,
    y: 0,
    transition: { scale }
  }
}
