import { RRule } from '@bonobolabs/rrule'
import { isArray, includes, get } from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import { getDueDay, parseDue } from './dates'

export const getRRule = action => {
  const rule = get(action, 'repeat.rule')
  if (!rule) return null

  try {
    // use repeat string from rep
    const options = RRule.parseString(rule)

    // and due date from Action
    // RRule expects a UTC date but with local time
    const dueDate = parseDue(getDueDay(action))
      .utc(true)
      .startOf('day')

    options.dtstart = dueDate.toDate()
    // options.tzid = getTZString()
    // options.byhour = 0
    // options.byminute = 0
    // options.bysecond = 0

    // create RRule
    return new RRule(options)
  } catch (e) {
    console.error(`Invalid rrule ${rule} - ${e}`, action)
    return null
  }
}

export const getRepeatText = (rrule, condenseOutput = false) => {
  if (!rrule) {
    return null
  }

  // TODO i18n
  return rrule.toText(undefined, undefined, undefined, { condenseOutput })
}

export const getActionsRepeatText = (action, condenseOutput) => {
  const rep = action.repeat

  // handle freq=never (set by iOS)
  if (rep && includes(rep.rule, 'FREQ=NEVER')) {
    return null
  }
  return getRepeatText(getRRule(action), condenseOutput)
}

export const getRepeatFrequency = rule => {
  const freqs = {
    [RRule.DAILY]: 'day',
    [RRule.WEEKLY]: 'week',
    [RRule.MONTHLY]: 'month',
    [RRule.YEARLY]: 'year'
  }
  return freqs[rule.options.freq]
}

export const getFreqNum = frequencyStr => {
  const freqs = {
    day: RRule.DAILY,
    week: RRule.WEEKLY,
    month: RRule.MONTHLY,
    year: RRule.YEARLY
  }
  return freqs[frequencyStr]
}

export const getIntervalText = (rule, interval) => {
  // TODO i18n pluralize
  const freq = getRepeatFrequency(rule)
  return pluralize(freq, interval, true)
}

export const getEndsText = count => {
  // TODO i18n pluralize
  if (count === null) {
    return 'After a number of times'
  } else {
    return `After ${pluralize('time', count, true)}`
  }
}

const isValidArr = val => !!(val && val.length)

const arrOrDefault = (val, defaultValue) => {
  return isValidArr(val) ? val : defaultValue
}

export const getMonthRepeatDetails = rule => {
  const date = moment(rule.options.dtstart).utc()

  const bymonthday = arrOrDefault(
    rule.options.bymonthday,
    [date.date()] // "date" (day) of month
  )
  const bysetpos = arrOrDefault(
    rule.options.bysetpos,
    [getNthDayInMonth(date)] // e.g. 2 (second saturday in month)
  )
  const byweekday = arrOrDefault(
    rule.options.byweekday,
    [date.isoWeekday() - 1] // e.g. 0 (Monday)
  )

  return {
    bymonthday,
    bysetpos,
    byweekday,
    usingMonthDay: isValidArr(rule.options.bymonthday)
  }
}

const arrayHead = val => {
  if (isArray(val) && val.length) return val[0]
  else return val
}

export const getWeekdayName = weekdayNum => {
  // weekdayNum is RRule day of week - 0 = Monday
  // isoWeekDay 1 = Monday
  weekdayNum = arrayHead(weekdayNum)
  return moment()
    .isoWeekday(weekdayNum + 1)
    .format('dddd')
}

export const getNthDayInMonth = date => {
  // e.g. date is 2nd sat in month => 2
  return Math.ceil(date.date() / 7)
}

export const ordinalSuffix = num => {
  num = arrayHead(num)
  // e.g. 1 => 1st, 2 => 2nd, etc. using moment for i18n
  return moment([2019, 1, num]).format('Do')
}

export const newRule = () => new RRule({ freq: RRule.DAILY })
