import { fork, call, put, select } from 'redux-saga/effects'
import { UPDATE_ACTION, DELETE_ACTION_FOR_REAL } from '../actions/types'
import { actionSelector, uidSelector } from '../actions/selectors'
import { dateNow } from '../utils/dates'

export function* updateAction(api, actionId, update) {
  // console.log(actionId, update)
  try {
    yield call(api.updateAction, actionId, update)
    // console.log('updateAction response', response)
  } catch (e) {
    // console.log(`updateAction error`, e)
  }
}

export function* updateActionLocallyThenRemotely(api, actionId, update) {
  update.lastUpdatedAt = dateNow()
  update.lastUpdatedBy = yield select(uidSelector)

  // blocking local state update
  yield put({
    type: UPDATE_ACTION,
    actionId,
    data: update
  })

  // non-blocking firebase update
  // TODO catch error & revert local update
  yield fork(updateAction, api, actionId, update)
}

export function* softDeleteAction(api, actionId) {
  const action = yield select(actionSelector, actionId)
  if (action == null) {
    console.warn(`Cannot delete null Action - id: ${actionId}`)
    return
  }

  yield put({ type: DELETE_ACTION_FOR_REAL, actionId })

  // mark Action as deleted
  yield fork(updateAction, api, actionId, {
    deleted: true,
    deletedAt: dateNow(),
    markedForDeletion: api.delete(),
    markedForDeletionAt: api.delete(),
    lastUpdatedAt: dateNow(),
    lastUpdatedBy: yield select(uidSelector)
  })
}
