import { init as SentryInit } from '@sentry/browser'
import { ExtraErrorData } from '@sentry/integrations'
import { sanitiseURL } from './utils/href'
import { environment, appVersion, sentryURL, serverName } from './config'
import { getTZString } from './utils/timezone'

const beforeBreadcrumb = (breadcrumb, hint) => {
  // Add aria label to breadcrumb on click
  if (breadcrumb.category === 'ui.click') {
    const { target } = hint.event
    if (target && target.ariaLabel) {
      breadcrumb.message = target.ariaLabel
    }
  }
  return breadcrumb
}
const beforeSend = (event, hint) => {
  // sanitise Action URLs to remove titles/ids
  event.request.url = sanitiseURL(event.request.url)
  return event
}

export const init = () => {
  if (environment !== 'development')
    SentryInit({
      dsn: sentryURL,
      release: appVersion || 'development',
      serverName: serverName || 'local',
      environment,
      beforeBreadcrumb,
      beforeSend,
      integrations: [new ExtraErrorData()]
    })
}

export const addTags = scope => {
  scope.setTag('language', window.navigator.language)
  scope.setTag('timezone', getTZString())
}
