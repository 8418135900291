import { memoize } from 'lodash'
import {
  darken,
  getLuminance,
  lighten,
  parseToRgb,
  toColorString
} from 'polished'

export const contrastingColor = memoize(
  bg => (getLuminance(bg) < 0.05 ? '#fff' : '#000')
)

export const lightenIfPoss = (amount, color) =>
  // white is a special case - cannot lighten any more
  color === '#FFFFFF'
    ? darkenHSV(amount + 0.02, color)
    : lightenHSV(amount, color)

export const darkenIfPoss = (amount, color) =>
  // black is a special case - cannot darken any more
  color === '#000' ? lightenHSV(amount + 0.02, color) : darkenHSV(amount, color)

const rgb2hsv = rgb => {
  const { red: r, green: g, blue: b } = rgb
  const min = Math.min(r, g, b)
  const max = Math.max(r, g, b)
  const delta = max - min
  let h, s, v

  if (max === 0) {
    s = 0
  } else {
    s = (delta / max) * 100
  }

  if (max === min) {
    h = 0
  } else if (r === max) {
    h = (g - b) / delta
  } else if (g === max) {
    h = 2 + (b - r) / delta
  } else if (b === max) {
    h = 4 + (r - g) / delta
  }

  h = Math.min(h * 60, 360)

  if (h < 0) {
    h += 360
  }

  v = (max / 255) * 100

  return { h, s, v }
}

const asRGB = (r, g, b) => ({
  red: Math.floor(r),
  green: Math.floor(g),
  blue: Math.floor(b)
})

const hsv2rgb = hsv => {
  const h = hsv.h / 60
  const s = hsv.s / 100
  let v = hsv.v / 100

  const hi = Math.floor(h) % 6

  const f = h - Math.floor(h)
  const p = 255 * v * (1 - s)
  const q = 255 * v * (1 - s * f)
  const t = 255 * v * (1 - s * (1 - f))
  v = 255 * v

  switch (hi) {
    default:
      return
    case 0:
      return asRGB(v, t, p)
    case 1:
      return asRGB(q, v, p)
    case 2:
      return asRGB(p, v, t)
    case 3:
      return asRGB(p, q, v)
    case 4:
      return asRGB(t, p, v)
    case 5:
      return asRGB(v, p, q)
  }
}

const guard = (lowerBoundary, upperBoundary, value) =>
  Math.max(lowerBoundary, Math.min(upperBoundary, value))

export const lightenHSV = (amount, color) => {
  const hsv = rgb2hsv(parseToRgb(color))

  hsv.v = guard(0, 100, hsv.v + amount * 100)

  return toColorString(hsv2rgb(hsv))
}

export const darkenHSV = (amount, color) => {
  const hsv = rgb2hsv(parseToRgb(color))

  hsv.v = guard(0, 100, hsv.v - amount * 100)

  return toColorString(hsv2rgb(hsv))
}

export const borderLumThreshold = 0.005

export const meetsLuminanceThreshold = colour =>
  getLuminance(colour) < borderLumThreshold

export const getContrastingBorder = memoize((bg, defaultBorder = 'initial') => {
  if (!bg) {
    throw new Error('A background colour is required')
  }

  return meetsLuminanceThreshold(bg)
    ? `inset 0 0 0 1px ${lighten(0.1, bg)}`
    : defaultBorder
})

export const getContrastingBg = memoize(bg => {
  if (!bg) {
    throw new Error('A background colour is required')
  }

  const scalar = 0.05

  return meetsLuminanceThreshold(bg)
    ? '#000' // dark colours are darkened to pure black
    : lightenHSV(scalar, darken(scalar, bg))
})
