import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import { easing } from 'popmotion'
import { Flex } from 'rebass'
import { createGlobalStyle } from 'styled-components'
import { Fixed } from './Fixed'
import { CloseLink } from './SidebarCloseLink'
import { navHeight } from '../TopNav'
import { darken } from 'polished'
import getBrowserScrollbarWidth from '../utils/getScrollbarWidth'
import useCustomScrollbars, {
  scrollbarWidth
} from '../utils/useCustomScrollbars'
import { getContrastingBorder } from '../../utils/color'

const hasVisibleScrollbars = getBrowserScrollbarWidth() > 0
export const sidebarWidth = 430

const RightBodyMargin = createGlobalStyle`
main {
  padding-right: ${({ show }) =>
    show ? `${sidebarWidth - (hasVisibleScrollbars ? 0 : 15)}px` : 0};
}
`

const bottomBarHeight = 95

// extra wide so doesn't show edge on bounce animate in
export const rightEdgeExtra = 100

const { createAnticipateEasing } = easing
const anticipate = createAnticipateEasing(1.3)

const PosedFixed = posed(Fixed)({
  open: {
    x: 0,
    backgroundColor: ({ bg }) => bg
  },
  closed: {
    x: '100%',
    transition: {
      x: {
        ease: anticipate
      }
    }
  }
})

export const Sidebar = ({ bg, color, open, children, ...rest }) => {
  const [showBodyMargin, setShowBodyMargin] = useState(false)

  useEffect(
    () => {
      // add body margin as soon as sidebar is shown
      if (open) setShowBodyMargin(true)
      else
        setTimeout(() => {
          setShowBodyMargin(false)
          // delay removing body margin until sidebar is mostly gone
        }, 150)
    },
    [open]
  )
  const [ref, scrollbarsShown, scrollbarStyles] = useCustomScrollbars(
    children,
    null,
    bg
  )

  return (
    <PosedFixed
      right={-rightEdgeExtra}
      pr={rightEdgeExtra}
      top={navHeight} // top nav height
      bottom={0}
      pb={bottomBarHeight}
      width={sidebarWidth + rightEdgeExtra}
      bg={darken(0.05, bg)}
      color={color}
      // transition smoothly between background colours
      poseKey={bg}
      role="complementary"
      aria-label="Action sidebar"
      inert={open ? null : 'inert'}
      pose={open ? 'open' : 'closed'}
      css={{
        borderTopLeftRadius: hasVisibleScrollbars ? null : '21px',
        borderBottomLeftRadius: hasVisibleScrollbars ? null : '21px',
        boxShadow: getContrastingBorder(bg),
        overflow: 'hidden',
        '@media screen and (max-width:1224px)': {
          boxShadow: '-2px 0 0 0 rgba(0,0,0,0.1)'
        }
      }}
      {...rest}
    >
      <Flex
        flexDirection="column"
        css={{
          height: '100%',
          overflowY: 'auto',
          ...scrollbarStyles
        }}
        ref={ref}
        p={30}
        pb={35}
        pr={30 - (scrollbarsShown ? scrollbarWidth : 0)}
      >
        {open ? <CloseLink color={color} bg={darken(0.05, bg)} /> : null}
        {children}
      </Flex>
      <RightBodyMargin show={showBodyMargin} />
    </PosedFixed>
  )
}
Sidebar.propTypes = {
  children: PropTypes.node,
  bg: PropTypes.string,
  color: PropTypes.string,
  open: PropTypes.bool
}
Sidebar.defaultProps = {
  color: '#ffffff',
  bg: '#000000',
  open: true
}
