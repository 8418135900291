import React from 'react'
import { Text, Flex, Box } from 'rebass'

import { Heading } from './Heading'
import { width as actionWidth } from './ActionCard'
import { padding as listPadding } from './List'
import DeleteUndo from '../DeleteUndo'
import { FlexGrow } from './Flex'
import { navHeightPx } from '../TopNav'

import useCustomScrollbars, {
  scrollbarWidth
} from '../utils/useCustomScrollbars'
import { getBGColor } from '../../constants/actionsThemeColours'

const listMargin = 4
export const listWidth = actionWidth + listPadding * 2 + listMargin * 2

export const ListsWrapper = ({ darkMode, children }) => {
  const bg = getBGColor(darkMode)
  const [ref, scrollbarsShown, scrollbarStyles] = useCustomScrollbars(
    children,
    null,
    bg
  )

  return (
    <Box
      pt={4}
      pr={20 - (scrollbarsShown ? scrollbarWidth : 0)}
      pl={40 - 4}
      pb={4}
      flex={1}
      css={{
        height: `calc(100vh - ${navHeightPx})`,
        overflowY: 'auto',
        overflowX: 'hidden',
        ...scrollbarStyles
      }}
      ref={ref}
      role="region"
      aria-label="Lists"
    >
      <Flex
        mb={
          44 // to line up with Schedule "Today" title
        }
        aria-hidden={true}
      >
        <Heading mb={0} pl={0} darkMode={darkMode}>
          Lists
        </Heading>
        <FlexGrow />
        <DeleteUndo darkMode={darkMode} />
      </Flex>
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fit, minmax(min-content, ${listWidth}px))`
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export const NoLists = () => (
  <Box mb={4} lineHeight={1.3}>
    <Text color="inherit" css={{ textAlign: 'center' }}>
      Create a new List to organise your&nbsp;Actions
    </Text>
  </Box>
)
