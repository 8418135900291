import { get, isDate } from 'lodash'
import {
  flow,
  groupBy,
  pickBy,
  mapValues,
  toPairs,
  orderBy,
  omitBy
} from 'lodash/fp'
import moment from 'moment'
import { isCompleted } from './actionFilters'

export const getCompletionDate = action => {
  const timestamp = get(action, 'completionDate', null)
  if (timestamp === null) return null

  // timestamp should be a date here
  if (!isDate(timestamp)) {
    console.warn('completionDate is not a date', timestamp)
    return null
  }
  const date = moment(timestamp)
  if (!date.isValid()) return null

  return date
}

const getThisWeek = () => moment().startOf('week')
const getLastWeek = () =>
  moment()
    .subtract(1, 'week')
    .startOf('week')

export const roundCompletionDate = action => {
  const date = getCompletionDate(action)
  if (date === null) return null

  const thisWeek = getThisWeek()
  const lastWeek = getLastWeek()

  if (date.isAfter(thisWeek)) {
    // TODO i18n
    return 'This Week'
  } else if (date.isAfter(lastWeek)) {
    return 'Last Week'
  } else {
    // round by month
    return date.startOf('month').format('MMMM Y')
  }
}

export const groupByCompletion = actions => {
  const grouped = flow(
    pickBy(isCompleted),
    omitBy('markedForDeletion'),
    // sort Actions by completion date so resultant object is sorted descending
    orderBy('completionDate', 'desc'),
    // group by each Action's rounded completion date
    // e.g. {a, b} => {this week: [a], last week: [b]}
    groupBy(roundCompletionDate),
    // sort actions in each group
    mapValues(orderBy('completionDate', 'desc')),
    // convert from object to [['this week', [a]], ['last week': [b]], ... ]
    toPairs
  )(actions)

  // drop no date
  delete grouped.null

  return grouped
}
