import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { memoize } from 'lodash'
import { getLuminance, saturate } from 'polished'
import getBrowserScrollbarWidth from '../utils/getScrollbarWidth'
import { darkenHSV as darken, lightenHSV as lighten } from '../../utils/color'

export const scrollbarWidth = 8

export const getScrollbarColour = memoize(bg => {
  const lum = getLuminance(bg)
  return lum > 0.9
    ? darken(0.3, bg) // very light/white
    : lum > 0.05
      ? // most colours
        darken(0.3, saturate(0.4, bg))
      : // very dark/black
        lighten(0.2, bg)
})

export const customScrollbarStyle = memoize(bg => {
  const scrollbarColor = getScrollbarColour(bg)
  return {
    '::-webkit-scrollbar': {
      width: `${scrollbarWidth}px`
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: `${scrollbarWidth / 2}px`,
      backgroundColor: scrollbarColor
    },

    scrollbarColor: `${scrollbarColor} transparent`
  }
})

export const noScrollbarStyle = {
  '::-webkit-scrollbar': { width: 0 }
}

const useCustomScrollbars = (children, parentRef = null, bg) => {
  const ref = useRef(null)
  const [contentCanScroll, setContentCanScroll] = useState(false)
  const [shouldStyleScrollbars, setShouldStyleScrollbars] = useState(false)
  const [style, setStyle] = useState({})
  const hasVisibleScrollbars = getBrowserScrollbarWidth() > 0

  useImperativeHandle(parentRef, () => ref.current)

  // call when children size changes
  const handleResize = useCallback(
    () => {
      if (!ref.current) return
      const node = ref.current
      setContentCanScroll(node.scrollHeight > node.clientHeight)
    },
    [ref]
  )

  // handle ref change
  const setRef = useCallback(node => {
    if (ref.current) {
      // cleanup on old ref if necessary
    }

    if (node) {
      setContentCanScroll(node.scrollHeight > node.clientHeight)
    }
    ref.current = node
  }, [])

  // handle resize, children change, etc
  // some logic from https://github.com/rehooks/component-size
  useLayoutEffect(
    () => {
      if (!ref.current) return
      if (!hasVisibleScrollbars) return
      let rafId

      handleResize()

      if (typeof ResizeObserver === 'function') {
        let resizeObserver = new ResizeObserver(() => {
          rafId = window.requestAnimationFrame(() => {
            handleResize()
          })
        })
        resizeObserver.observe(ref.current)

        return () => {
          resizeObserver.disconnect(ref.current)
          resizeObserver = null
          if (rafId) window.cancelAnimationFrame(rafId)
        }
      }
    },
    [ref, children, handleResize, hasVisibleScrollbars]
  )

  useEffect(
    () => {
      setShouldStyleScrollbars(!!(contentCanScroll && hasVisibleScrollbars))
    },
    [contentCanScroll, hasVisibleScrollbars]
  )

  useEffect(
    () => {
      setStyle(
        hasVisibleScrollbars
          ? shouldStyleScrollbars
            ? customScrollbarStyle(bg)
            : noScrollbarStyle
          : {}
      )
    },
    [shouldStyleScrollbars, hasVisibleScrollbars, setStyle, bg]
  )

  return [setRef, shouldStyleScrollbars, style]
}

export default useCustomScrollbars
