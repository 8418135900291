import React from 'react'
import PropTypes from 'prop-types'

export const NewListIcon = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1"
  >
    <line x1="1.5" y1="12.5" x2="23.5" y2="12.5" />
    <line x1="12.5" y1="23.5" x2="12.5" y2="1.5" />
  </svg>
)

NewListIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

NewListIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor'
}

NewListIcon.displayName = 'NewListIcon'
