import React from 'react'
import PropTypes from 'prop-types'

export const TickIcon = ({ size, color }) => (
  <svg
    viewBox="0 0 111.19 77.4"
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="5"
  >
    <polyline points="108.69 2.5 36.29 74.9 2.5 41.11" />
  </svg>
)

TickIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

TickIcon.defaultProps = {
  size: '28',
  color: 'currentColor'
}
