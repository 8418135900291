import { defaults, sortBy } from 'lodash'
import { filter, flow, groupBy, mapValues, omitBy } from 'lodash/fp'
import moment from 'moment'
import { isCompleted } from './actionFilters'
import { getScheduleObject } from './dates'
import { filterDate, formatDate } from './events'

const orderDeadlineActions = actions => sortBy(actions, ['title'])

export const groupDeadlinesByDate = actions => {
  const grouped = flow(
    filter('deadlineDate'),
    // hide completed
    omitBy(isCompleted),
    // hide deleted
    omitBy('deleted'),
    omitBy('markedForDeletion'),
    // filter by only scheduled this week
    // (so we don't show all future deadlines in Later section)
    filter(action => filterDate(moment(action.deadlineDate))),
    groupBy(action => formatDate(moment(action.deadlineDate))),
    mapValues(orderDeadlineActions)
  )(actions)

  // drop no date
  delete grouped.null

  return defaults(grouped, getScheduleObject())
}
