import React from 'react'
import PropTypes from 'prop-types'

export const DeadlineIcon = ({ size, color, opacity }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill={color}
    fillOpacity={opacity}
  >
    <path d="M20.46,20.88c-.21-.97-.69-1.85-1.39-2.55l-4.19-4.21c-.89-.89-.89-2.34,0-3.23l4.19-4.21c.7-.7,1.18-1.58,1.39-2.55l.16-.72c.13-.59-.01-1.2-.39-1.67-.38-.47-.94-.74-1.54-.74H6.32c-.6,0-1.17,.27-1.54,.74-.38,.47-.52,1.08-.39,1.67l.16,.72c.21,.97,.69,1.85,1.39,2.55l4.19,4.21c.89,.89,.89,2.34,0,3.23l-4.19,4.21c-.7,.7-1.18,1.58-1.39,2.55l-.16,.72c-.13,.59,.01,1.2,.39,1.67,.38,.47,.94,.74,1.54,.74h12.36c.6,0,1.17-.27,1.54-.74,.38-.47,.52-1.08,.39-1.67l-.16-.72Zm-12.06,2.12l2.68-3.05c.71-.71,1.06-1.64,.92-2.57v-4.87c.14-.93-.21-1.86-.92-2.57l-3.97-3.93h10.79l-3.97,3.93c-.71,.71-1.06,1.64-.92,2.57v4.87c-.14,.93,.21,1.86,.92,2.57l2.68,3.05H8.4Z" />
  </svg>
)

DeadlineIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  opacity: PropTypes.number
}

DeadlineIcon.defaultProps = {
  size: '25',
  color: 'currentColor',
  opacity: 1
}
