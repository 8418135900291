const supportsResizeObserver = () =>
  'ResizeObserver' in global &&
  'ResizeObserverEntry' in global &&
  'contentRect' in ResizeObserverEntry.prototype

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
function loadPolyfills() {
  const polyfills = []

  if (!supportsResizeObserver()) {
    polyfills.push(
      import(/* webpackChunkName: "resize-observer" */ '@juggle/resize-observer')
    )
  }

  return Promise.all(polyfills)
}

export default loadPolyfills
