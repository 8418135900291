import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AbsoluteSVG = styled.svg`
  position: absolute;
  display: block;
`

export const UndoIcon = ({ width, height, color, ...rest }) => (
  <AbsoluteSVG
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M4.5,7.5h8.268a7.729,7.729,0,0,1,7.845,7.3,7.5,7.5,0,0,1-7.5,7.7H8.5" />
    <line x1="4.5" y1="7.5" x2="10.5" y2="1.5" />
    <line x1="10.5" y1="13.5" x2="4.5" y2="7.5" />
  </AbsoluteSVG>
)

UndoIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
}

UndoIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor'
}
