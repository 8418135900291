import { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

export const useTooltipRebuild = (deps = []) => {
  useEffect(() => {
    ReactTooltip.rebuild()
    return () => {
      ReactTooltip.hide()
    }
    // eslint-disable-next-line
  }, deps)
}
