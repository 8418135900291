import React from 'react'
import PropTypes from 'prop-types'

export const NotesIcon = ({ size, color, opacity }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fillOpacity={opacity}
    fill={color}
  >
    <rect x="2" y="4" width="21" height="4" rx="1.36" ry="1.36" />
    <rect x="2" y="10" width="21" height="2" rx="1" ry="1" />
    <rect x="2" y="14" width="12" height="2" rx="1" ry="1" />
    <rect x="2" y="18" width="17" height="2" rx="1" ry="1" />
  </svg>
)

NotesIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  opacity: PropTypes.number
}

NotesIcon.defaultProps = {
  size: '25',
  color: 'currentColor',
  opacity: 1
}
