import { isNil, has, isEmpty, reduce, get as normalGet } from 'lodash'
import {
  flow,
  get,
  sortBy,
  identity,
  flatten,
  map,
  reject,
  capitalize
} from 'lodash/fp'
import moment from 'moment'

const convertReminderTime = time =>
  moment
    .unix(time)
    .utc()
    .format('LT')
    .toLowerCase() // AM -> am

const getTodayText = rem => {
  const times = flow(
    reject(isNil),
    sortBy(identity),
    map(convertReminderTime)
  )(rem.times)
  if (isEmpty(times)) return null
  return `on the day at ${reduceListify(false)(times)}`
}

const getBeforehandText = rem => {
  const durations = flow(
    reject(isNil),
    sortBy(identity),
    map(d => moment.duration(d, 'days').humanize())
  )(rem.dateOffsets)
  if (isEmpty(durations)) return null
  return `${reduceListify(false)(durations)} beforehand`
}

export const humaniseRem = rem => {
  let output = []
  if (!rem) return []
  if (has(rem, 'times')) output.push(getTodayText(rem))
  if (has(rem, 'dateOffsets')) output.push(getBeforehandText(rem))
  return output
}

const listify = (newlines = true, and = '&') => (
  str,
  value,
  index,
  collection
) => {
  // nice humanised comma separated list
  // [a, b, c] -> "a,\n b &\nc"
  const sep = index === collection.length - 1 ? ` ${and}` : ','
  return `${str}${sep}${newlines ? '\n' : ' '}${value}`
}

export const reduceListify = (newlines = true, and = '&') => collection =>
  reduce(collection, listify(newlines, and))

export const getReminder = get('myData.reminders')

export const getSingleReminder = action => ({
  time: normalGet(
    action,
    // get first time
    ['myData', 'reminders', 'times', 0],
    null
  ),
  dateOffset: normalGet(
    action,
    // get first date offset
    ['myData', 'reminders', 'dateOffsets', 0],
    null
  )
})

export const getHasReminder = action => {
  const reminder = getSingleReminder(action)
  const { time, dateOffset } = reminder
  return !!(time || dateOffset)
}

export const getActionReminderText = flow(
  getReminder,
  humaniseRem,
  flatten,
  reject(isNil),
  reduceListify(),
  capitalize
)

export const getDateOffset = (scheduledDate, reminderDate) => {
  reminderDate = moment(reminderDate).startOf('day')
  const days = scheduledDate.startOf('day').diff(reminderDate, 'days')
  // prevent -0
  return days === 0 ? 0 : -days
}

export const getActionReminderTime = action => {
  const { time } = getSingleReminder(action)
  if (time == null) return null
  return convertReminderTime(time)
}
