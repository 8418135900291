import React from 'react'
import { Heading as BaseHeading } from 'rebass'

// Used for Logbook / Lists / Schedule headings
export const Heading = ({ darkMode, ...props }) => (
  <BaseHeading
    is="h1"
    fontSize={30}
    fontWeight={700}
    mb={4}
    color={darkMode ? 'white' : 'black'}
    {...props}
  />
)

// used for the text under Heading - "Actions this week" or "Completed Actions"
export const SubHeading = ({ darkMode, ...props }) => (
  <BaseHeading
    fontSize={0}
    fontWeight="normal"
    color={darkMode ? 'white' : 'black'}
    mb={4}
    {...props}
  />
)

// used for Today, Tomorrow, ... in schedule,
// This week, Last Week, ... in logbook
export const SectionTitle = ({ darkMode, ...props }) => (
  <BaseHeading
    is="h2"
    fontSize={19}
    fontWeight={700}
    color={darkMode ? 'white' : 'black'}
    {...props}
  />
)
