import { getScheduleObject, dueformat, nextWeek } from './dates'
import { defaults, sortBy } from 'lodash'
import { flow, groupBy, mapValues, map, flatten, filter } from 'lodash/fp'
import moment from 'moment'
import { parseDue } from './dates'

const dateRange = (startDate, endDate) => {
  let d = startDate.clone()
  let dates = []

  while (d.isBefore(endDate)) {
    dates.push(d.clone())
    d.add(1, 'days')
  }
  return dates
}

export const getEventDates = event => {
  let start = null
  let end = null
  if (event.start.date) {
    // full day event
    start = moment(event.start.date)
    end = moment(event.end.date)
  } else if (event.start.dateTime) {
    // event has start/end times
    start = moment.parseZone(event.start.dateTime).local()
    end = moment.parseZone(event.end.dateTime).local()
  }
  if (start === null) return []

  if (!start.isSame(end, 'day')) {
    return dateRange(start, end)
  }

  return [start]
}

export const filterDate = date => {
  if (date.isBefore(moment().startOf('day'))) {
    // in past - ignore
    return false
  }

  if (date.isSameOrAfter(nextWeek().startOf('day'))) {
    // in future - ignore
    return false
  }
  return true
}

export const formatDate = date => date.format(dueformat)

const getStartTime = event => {
  if (event.start.date) return 'All Day'

  const start = moment.parseZone(event.start.dateTime).local()
  return start.unix()
}

export const orderEvents = events =>
  sortBy(events, ['start.date', getStartTime, 'calendar'])

export const groupEventsByDate = events => {
  // Groups actions by date, and adds empty arrays for other days this week
  const grouped = flow(
    map(event => getEventDates(event).map(date => ({ date, event }))),
    flatten,
    filter(obj => filterDate(obj.date)),
    groupBy(obj => formatDate(obj.date)),
    mapValues(map(obj => obj.event)),
    mapValues(orderEvents)
  )(events)

  // drop no date
  delete grouped.null

  return defaults(grouped, getScheduleObject())
}

export const getDisplayTime = (event, date) => {
  if (event.start.date) return 'All Day'

  const start = moment.parseZone(event.start.dateTime).local()
  const end = moment.parseZone(event.end.dateTime).local()

  const dateToShow = parseDue(date)
  if (dateToShow.isSame(start, 'day')) {
    return start.format('h:mm A')
  } else if (dateToShow.isSame(end, 'day')) {
    return end.format('→ h:mm A')
  } else {
    return 'All Day'
  }
}
