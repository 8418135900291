import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { X as CloseIcon } from 'react-feather'
import { LinkWithCircle } from './Link'

export const CloseLink = connect(state => ({
  logbookVisible: state.location.logbookVisible
}))(
  React.memo(({ color, bg, logbookVisible }) => {
    return (
      <LinkWithCircle
        color={color}
        bg="inherit"
        to={logbookVisible ? '/logbook' : '/'}
        aria-label="Close sidebar"
        ml={-10}
        mt="-5px"
        mb={35}
        css={{
          opacity: 0.5,
          display: 'flex',
          width: '40px',
          height: '40px',
          flexShrink: 0,
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': { opacity: 0.8 }
        }}
      >
        <CloseIcon />
      </LinkWithCircle>
    )
  })
)

CloseLink.propTypes = {
  color: PropTypes.string
}
CloseLink.defaultProps = {
  color: 'white'
}

CloseLink.displayName = 'CloseLink'
