import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import system from '@rebass/components'
import styled from 'styled-components'
import posed from 'react-pose'
import { Flex, Box, Text } from 'rebass'
import { rem, transparentize } from 'polished'
import { DeadlineIcon, FatTick, ReminderIcon } from './icons'
import { Link } from './Link'
import { darken } from 'polished'
import { lightenIfPoss } from '../../utils/color'
import { DeterminateProgress, IndeterminateProgress } from './SubtasksProgress'
import { getContrastingBorder } from '../../utils/color'
export const width = 300

const ActionPriority = React.lazy(() =>
  import(/* webpackChunkName: "actionpriority" */ './ActionPriority')
)

export const cardBorderRadius = '13px'

const FlexWithNoOpen = React.forwardRef(({ isOpen, ...props }, ref) => (
  <Flex ref={ref} {...props} />
))

const hoverDarken = 0.02

const PosedFlex = posed(FlexWithNoOpen)({
  init: {
    backgroundColor: ({ bg, isOpen }) => (isOpen ? darken(0.1, bg) : bg)
  },
  parenthover: {
    backgroundColor: ({ bg, isOpen }) => darken(isOpen ? 0.13 : hoverDarken, bg)
  }
})

const getBoxShadow = ({ inSchedule, isDragging, bg }) => {
  // contrasting border for low-luminance themes
  const contrasting = getContrastingBorder(bg)

  // shadow when dragged
  const dragging = isDragging ? '2px 5px 20px 0px rgb(0 0 0 / 30%)' : null

  // both null
  if (contrasting === 'initial' && dragging == null) {
    return null
  }

  if (contrasting === 'initial') {
    return dragging
  }

  if (dragging == null) {
    return contrasting
  }

  // combine
  return `${contrasting}, ${dragging}`
}

export const CardWrapper = React.forwardRef(
  ({ children, bg, isOpen, isDragging, inSchedule, ...props }, ref) => (
    <PosedFlex
      alignItems="center"
      justifyContent="center"
      width={width}
      fontSize={rem(16)}
      px={19}
      py={19}
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',

        position: 'relative',
        borderRadius: cardBorderRadius,
        minHeight: '80px',

        boxShadow: getBoxShadow({ inSchedule, isDragging, bg }),

        // prevent anchor tag default underline
        textDecoration: 'none',

        // prevent flickering
        backfaceVisibility: 'hidden',

        // fix wrapping in Safari
        overflowWrap: 'break-word',

        fontWeight: 500
        // letterSpacing: '0.02em'
      }}
      ref={ref}
      bg={bg}
      isOpen={isOpen}
      poseKey={`${bg}-${isOpen ? 'open' : 'closed'}`}
      {...props}
    >
      {children}
    </PosedFlex>
  )
)

const MetaText = styled(Text)({
  fontSize: rem(10),
  textTransform: 'uppercase',
  letterSpacing: '-0.03em'
})

const ListMetaLabel = ({ list }) => {
  if (!list) return null

  return (
    <MetaText mt="4px" fontWeight={500}>
      {list}
    </MetaText>
  )
}

const DateMetaLabel = ({ date, repeat, hasReminder, reminderTime }) => {
  if (date == null && repeat == null) return null

  return (
    <MetaText
      mt="2px"
      lineHeight="1"
      css={{ display: 'flex', alignItems: 'center' }}
      fontWeight="normal"
    >
      {hasReminder && (
        <>
          <Box is="span" mt="3px" mr={1}>
            <ReminderIcon size={10} />
          </Box>
          {reminderTime && (
            <Box is="span" mr={1}>
              {reminderTime}
            </Box>
          )}
        </>
      )}
      {date && <Text is="span">{date}</Text>}
      {repeat && (
        <Text is="span">
          &nbsp;
          {`(${repeat})`}
        </Text>
      )}
    </MetaText>
  )
}

const DeadlineMetaLabel = ({ deadline }) => {
  if (!deadline) return null

  return (
    <MetaText
      mt="4px"
      lineHeight="1"
      css={{ display: 'flex', alignItems: 'center' }}
      fontWeight="normal"
    >
      <Box is="span" mr="3px">
        <DeadlineIcon size={8} />
      </Box>
      {deadline}
    </MetaText>
  )
}

const MetaLabels = ({
  listName,
  displayDate,
  displayRepeat,
  displayDeadline,
  hasReminder,
  displayReminderTime
}) => {
  if (!(listName || displayDate || displayRepeat || displayDeadline))
    return null

  return (
    <>
      <ListMetaLabel list={listName} />
      <DateMetaLabel
        date={displayDate}
        repeat={displayRepeat}
        hasReminder={hasReminder}
        reminderTime={displayReminderTime}
      />
      <DeadlineMetaLabel deadline={displayDeadline} />
    </>
  )
}

const ProgressBar = React.memo(
  ({ subtasksCompleted: completed, subtasksTotal: total, color }) => {
    if (!total) return null

    return (
      <Flex
        mt={2}
        // use a fixed width not a %, otherwise it gets narrower when priority icon is shown
        width={120}
        ml={0}
        mr="auto"
        role="progressbar"
        aria-valuenow={completed}
        aria-valuemin="0"
        aria-valuemax={total}
        aria-label="Subtasks progress"
      >
        {total <= 10 ? (
          <DeterminateProgress
            total={total}
            completed={completed}
            color={color}
            height={3}
          />
        ) : (
          <IndeterminateProgress
            total={total}
            completed={completed}
            color={color}
            height={3}
          />
        )}
      </Flex>
    )
  }
)

const CardFront = React.memo(
  ({
    bg,
    color,
    title,
    listName,
    displayDate,
    displayRepeat,
    displayDeadline,
    subtasksCompleted,
    subtasksTotal,
    isCompleted,
    isOpen,
    isDragging,
    inSchedule,
    hasReminder,
    displayReminderTime,

    href,
    dragHandleProps,
    priority,
    ...rest
  }) => (
    <Link
      to={href}
      {...dragHandleProps}
      css={{
        // Required to be :focus (:focus-visible doesn't work here)
        '&:focus': {
          outline: 'none',
          '& > div': {
            boxShadow: `inset 0 0 0 4px ${transparentize(0.5, color)}`
          }
        }
      }}
      aria-label={title}
    >
      <CardWrapper
        isOpen={isOpen}
        isDragging={isDragging}
        inSchedule={inSchedule}
        bg={bg}
        color={color}
        {...rest}
      >
        <Box
          flex={1}
          css={{
            // fixes word-break on child
            minWidth: '1%'
          }}
        >
          <Text
            css={{
              textDecoration: isCompleted ? 'line-through' : null,
              overflowWrap: 'break-word',
              letterSpacing: '-0.01em'
            }}
            role="presentation"
          >
            {title}
          </Text>

          <MetaLabels
            listName={listName}
            displayDate={displayDate}
            displayRepeat={displayRepeat}
            displayDeadline={displayDeadline}
            hasReminder={hasReminder}
            displayReminderTime={displayReminderTime}
          />

          <ProgressBar
            subtasksCompleted={subtasksCompleted}
            subtasksTotal={subtasksTotal}
            color={color}
          />
        </Box>
        <Suspense fallback={null}>
          <ActionPriority
            color={color}
            priority={priority}
            animated
            hideWhenPriorityNotSet
            ml={2}
          />
        </Suspense>
      </CardWrapper>
    </Link>
  )
)
CardFront.displayName = 'CardFront'

CardFront.propTypes = {
  isCompleted: PropTypes.bool
}

CardFront.defaultProps = {
  bg: 'white',
  color: '#696969'
}

const iconTransition = {
  opacity: { duration: 0.05 },
  scale: {
    type: 'spring',
    stiffness: 700,
    damping: 25,
    mass: 1
  }
}

const PosedIcon = posed(FlexWithNoOpen)({
  hoverable: true,
  pressable: true,
  init: {
    opacity: 0,
    scale: 0.2,
    transition: iconTransition,
    boxShadow: ({ bg, isOpen }) =>
      `0 0 0 4px ${transparentize(1, darken(isOpen ? 0.13 : hoverDarken, bg))}`
  },
  parenthover: {
    opacity: 1,
    scale: 1,
    backgroundColor: ({ bg, isOpen }) =>
      lightenIfPoss(0.08, darken(isOpen ? 0.13 : hoverDarken, bg)),
    transition: iconTransition,
    boxShadow: ({ bg, isOpen }) =>
      `0 0 0 4px ${darken(isOpen ? 0.13 : hoverDarken, bg)}`
  },
  hover: {
    opacity: 1,
    scale: 1.05,
    transition: iconTransition,
    backgroundColor: ({ bg, isOpen }) =>
      lightenIfPoss(0.1, darken(isOpen ? 0.13 : hoverDarken, bg)),
    boxShadow: ({ bg, isOpen }) =>
      `0 0 0 3px ${darken(isOpen ? 0.13 : hoverDarken, bg)}`
  },
  press: { scale: 0.85 }
})

const circleSize = 36

const IconLink = React.memo(({ children, onClick, isOpen, bg, ariaLabel }) => (
  <Flex
    p={3}
    color="inherit"
    alignItems="center"
    justifyContent="center"
    // prevent tab focus on shortcut icons to allow for tabbing Actions in a list one by one
    // keyboard users can use enter to select Action, then keyboard shortcuts to complete/delete Action
    tabIndex={-1}
    onMouseDown={e => e.preventDefault()} // prevent focus after click
    css={{
      userSelect: 'none',
      pointerEvents: 'all'
    }}
  >
    <PosedIcon
      alignItems="center"
      justifyContent="center"
      bg={bg}
      isOpen={isOpen}
      aria-label={ariaLabel}
      css={{
        cursor: 'pointer',
        borderRadius: `${circleSize / 2}px`,
        width: `${circleSize}px`,
        height: `${circleSize}px`,
        // boxShadow: `0 1px 0 0 rgba(0,0,0,0.2), 0 0 10px -1px rgba(0,0,0,0.15), 0 0 5px 3px ${transparentize(
        //   0.2,
        //   bg
        // )}`,
        // boxShadow: `0 1px 0 0 rgba(0,0,0,0.1), 0 0 10px -1px rgba(0,0,0,0), 0 0 0 8px rgb(21, 116, 185)`,
        // boxShadow: '0 1px 0 0 rgba(0,0,0,0.15)',
        // svg: {
        //   display: 'block',
        //   transition: '0.1s ease all'
        // },
        // '&:hover': {
        //   svg: {
        //     transform: 'scale(1.1)'
        //   }
        // },
        '&:focus': { backgroundColor: 'rgba(1,1,1,0.07)', outline: 'none' },
        '&:active': { backgroundColor: 'rgba(0,0,0,0.07)' }
      }}
      onClick={onClick}
    >
      {children}
    </PosedIcon>
  </Flex>
))

const CardUnder = React.memo(
  ({ toggleComplete, clickDelete, isOpen, isCompleted, color, bg, style }) => (
    <Flex
      justifyContent="flex-end"
      css={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: '1px',
        bottom: '1px',
        borderRadius: cardBorderRadius,
        overflow: 'hidden',
        pointerEvents: 'none'
      }}
      style={style}
      color={color}
      aria-hidden={true}
    >
      {/* <IconLink
        onClick={clickDelete}
        isOpen={isOpen}
        bg={bg}
        ariaLabel="Delete"
      >
        <DeleteActionIcon size={16} />
      </IconLink> */}

      <IconLink
        onClick={toggleComplete}
        isOpen={isOpen}
        bg={bg}
        ariaLabel="Complete"
      >
        <FatTick size={20} checked={isCompleted} strokeWidth={5} />
      </IconLink>
    </Flex>
  )
)

CardUnder.propTypes = {
  toggleComplete: PropTypes.func
}

CardUnder.displayName = 'CardUnder'

const Relative = system(
  {
    extend: Box,
    // padding not margin - margin collapsing causes animation glitch on drag and drop
    py: '1px'
  },
  {
    position: 'relative',
    userSelect: 'none'
  },
  'space',
  'maxWidth'
)

const PosedRelative = posed(Relative)({
  hoverable: true,
  posePrefix: 'parent'
})

const ActionCard = React.memo(
  React.forwardRef(
    (
      {
        draggableProps,
        dragHandleProps,
        style,
        color,
        toggleComplete,
        isCompleted,
        clickDelete,
        index,
        isOpen,
        isDragging = false,
        inSchedule = false,
        bg,
        ...rest
      },
      ref
    ) => (
      <PosedRelative
        {...draggableProps}
        ref={ref}
        poseKey={`${bg}-${isOpen ? 'open' : 'closed'}`}
        pose={'init'}
      >
        <CardFront
          bg={bg}
          color={color}
          dragHandleProps={dragHandleProps}
          style={style}
          isOpen={isOpen}
          inSchedule={inSchedule}
          isDragging={isDragging}
          {...rest}
        />
        <CardUnder
          bg={bg}
          color={color}
          isOpen={isOpen}
          isCompleted={isCompleted}
          toggleComplete={toggleComplete}
          clickDelete={clickDelete}
          style={style && style.opacity < 1 ? { display: 'none' } : null}
        />
      </PosedRelative>
    )
  )
)
ActionCard.displayName = 'ActionCard'

ActionCard.propTypes = {
  // from react-dnd
  draggableProps: PropTypes.object,
  dragHandleProps: PropTypes.object,
  // action styling
  style: PropTypes.object,
  color: PropTypes.string,
  // props for scroll animation
  index: PropTypes.number,
  // defined on front/under
  ...CardFront.propTypes,
  ...CardUnder.propTypes
}

// const ScrollableActionCard = withScrollAnimation(ActionCard)
// export default ScrollableActionCard
export default ActionCard
