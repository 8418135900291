import { createSelector } from 'reselect'
import {
  get,
  flow,
  without,
  map,
  filter,
  omitBy,
  sortBy,
  pickBy,
  toPairs,
  identity,
  values,
  keys,
  head,
  isUndefined
} from 'lodash/fp'
import { get as normalGet, mapValues, orderBy, size } from 'lodash'
import moment from 'moment'

import { groupByDate, isCompleted, groupByCompletion } from '../utils'
import { groupEventsByDate } from '../utils/events'
import { groupDeadlinesByDate } from '../utils/deadlineScheduling'
import { getFirst } from '../utils/get'

import { deletionTime } from '../sagas/deleteAction'

export const actionsSelector = state => state.actions
export const actionSelector = (state, actionId) => state.actions[actionId]

export const subtasksSelector = actionId =>
  createSelector(
    actionsSelector,
    flow(
      get(actionId),
      get('subtasks'),
      toPairs,
      map(([subtaskId, subtask]) => ({ ...subtask, identifier: subtaskId })),
      values,
      sortBy('displayIndex')
    )
  )

export const subtasksLoadedSelector = actionId =>
  createSelector(
    actionsSelector,
    flow(
      get(actionId),
      get('subtasks'),
      s => !isUndefined(s)
    )
  )

export const subtaskDisplayIndexes = actionId =>
  createSelector(
    subtasksSelector(actionId),
    flow(
      map('displayIndex'),
      without([undefined]),
      sortBy(identity)
    )
  )

export const listsSelector = state => omitBy(list => list.deleted)(state.lists)

export const deletedListsSelector = state =>
  omitBy(list => !list.deleted)(state.lists)

export const deletedActionsSelector = state =>
  state.firestore.data.deletedActions

export const todaySelector = state => state.today
export const installDateSelector = get(
  'firebase.profile.actionsApp.installDate'
)
export const webInstallDateSelector = get(
  'firebase.profile.actionsApp.webInstallDate'
)
export const schemaVersionSelector = get(
  'firebase.profile.actionsApp.actionsMinSchemaVersion'
)

export const nonHiddenListsSelector = state =>
  flow(
    omitBy(list => list.isHidden || list.deleted || list.archived),
    toPairs,
    sortBy(([listId, list]) =>
      getFirst(list, ['myData.displayIndex', 'displayIndex'])
    )
  )(state.lists)

export const hiddenListIDSelector = state =>
  flow(
    pickBy(list => list.isHidden),
    keys,
    head
  )(state.lists)

export const listSelector = (state, listId) => state.lists[listId]

export const listActionsSelector = listId =>
  // use action.list rather than list.actions
  createSelector(
    actionsSelector,
    flow(
      // get Actions with action.list == listId
      pickBy(['list', listId]),

      // TODO - filter complete/incomplete actions in list
      // needs to be a preference
      omitBy(isCompleted),
      omitBy('markedForDeletion'),
      omitBy('deleted'),

      toPairs,
      // add ID as identifier prop
      map(([actionId, action]) => ({ ...action, identifier: actionId })),
      values,
      // sorted list of [ {action1}, {action2} ]
      sortBy('displayIndex')
    )
  )

export const listAllActionsSelector = listId =>
  // use action.list rather than list.actions
  createSelector(
    actionsSelector,
    flow(
      // get Actions with action.list == listId
      pickBy(['list', listId])
    )
  )

// used in Undo icons in nav
export const deletingActionsSelector = createSelector(
  actionsSelector,
  flow(
    pickBy(
      action =>
        action.markedForDeletion === true &&
        action.markedForDeletionAt !== undefined
    ),
    toPairs,
    sortBy(([actionId, action]) => action.markedForDeletionAt)
  )
)

const deletionDateHasPassed = d =>
  moment(d)
    .add(deletionTime, 'ms')
    .isBefore()

export const staleActionsToBeDeletedSelector = createSelector(
  actionsSelector,
  pickBy(action => {
    return (
      action.markedForDeletion === true &&
      action.markedForDeletionAt &&
      action.markedForDeletionAt.toDate &&
      deletionDateHasPassed(action.markedForDeletionAt.toDate())
    )
  })
)

export const firebaseSelector = state => state.firebase

export const firestoreSelector = state => state.firestore

export const scheduleSelector = createSelector(
  [actionsSelector, todaySelector],
  // including today to bust cache
  (actions, today) =>
    groupByDate(
      // archived lists
      omitBy('listData.archived')(
        // for during delete
        omitBy('markedForDeletion')(
          // for soft deletion
          omitBy('deleted')(actions)
        )
      )
    )
)
export const logbookSelector = createSelector(
  actionsSelector,
  flow(
    omitBy('markedForDeletion'),
    groupByCompletion
  )
)

// get Actions on schedule day
// Accepts a schedule day string - e.g. '20181023'
export const scheduleDaySelector = dayString =>
  createSelector(scheduleSelector, days => days[dayString] || null)

const getScheduleDisplayIndex = flow(
  map(action =>
    getFirst(action, ['myData.scheduleDisplayIndex', 'scheduleDisplayIndex'])
  ),
  without([undefined]),
  sortBy(identity)
)

// returns array of scheduleDisplayIndexes on day
export const scheduleDayIndexes = dayString =>
  createSelector(scheduleDaySelector(dayString), getScheduleDisplayIndex)

const getDisplayIndex = flow(
  map(list => getFirst(list, ['myData.displayIndex', 'displayIndex'])),
  without([undefined]),
  sortBy(identity)
)

// returns array of displayIndexes for list actions
export const listDisplayIndexes = listId =>
  createSelector(listActionsSelector(listId), getDisplayIndex)

const getListDisplayIndex = flow(
  omitBy(list => list.isHidden),
  getDisplayIndex
)

// returns array of displayIndexes for all lists
export const listsDisplayIndexes = state =>
  getListDisplayIndex(listsSelector(state))

export const uidSelector = state => state.firebase.auth.uid
export const displayNameSelector = state => state.firebase.auth.displayName

export const listNotificationSettingSelector = listId =>
  createSelector([s => listSelector(s, listId)], list => {
    // If this flag isn't present, it defaults to true
    return normalGet(list, ['myData', 'notificationsEnabled'], true)
  })

// TODO test memoization
// https://github.com/reduxjs/reselect#q-how-do-i-test-a-selector

export const unscheduledUnlisted = createSelector(
  actionsSelector,
  flow(
    pickBy(
      action =>
        // not scheduled
        get('due.day')(action) == null &&
        // on hidden list
        get('listData.isHidden')(action) === true
    )
  )
)

// events
export const googleCalendars = flow(
  state => normalGet(state, 'firestore.data.googleCalendars', {}),
  values,
  filter(c => c !== null)
)
export const googleAccounts = flow(
  state => normalGet(state, 'firestore.data.googleAccounts', {}),
  values,
  filter(c => c !== null)
)

export const eventsSelector = state => state.events

export const scheduleEventsSelector = createSelector(
  [eventsSelector, todaySelector],
  // including today to bust cache
  (events, today) => groupEventsByDate(events)
)

export const scheduleDeadlinesSelector = createSelector(
  [actionsSelector, todaySelector],
  // including today to bust cache
  (actions, today) => groupDeadlinesByDate(actions)
)

// collaborators
export const collaborators = flow(state =>
  normalGet(state, 'firestore.data.collaborators', {})
)

export const userSettings = state => state.userSettings

export const getInitials = name => {
  if (name == null || name === '') return '?'

  let initials = name
    .split(' ')
    // get just first char of each word
    .map(n => n[0])

  if (initials.length === 1) {
    return initials.join('')
  }

  const firstInitial = initials[0]
  const lastInitial = initials[initials.length - 1]
  return `${firstInitial}${lastInitial}`
}

const getListUsers = ({ userIds, collaborators, uid }) => {
  return orderBy(
    values(
      mapValues(userIds, userId => {
        const name = normalGet(collaborators, [userId, 'name'])
        const initials = getInitials(name)
        return {
          name,
          initials,
          isMe: uid === userId,
          userId
        }
      })
    ),
    ['isMe', 'name'],
    ['desc', 'asc']
  )
}

// returns an array of collaborators, e.g. [{name: "Alex", isMe: false, userId}, ...]
export const listCollaborators = listId =>
  createSelector(
    [collaborators, uidSelector, s => listSelector(s, listId)],
    (collaborators, uid, list) =>
      getListUsers({
        userIds: list.userIds,
        collaborators,
        uid
      })
  )

// returns a collection of initials if the list is shared and has collaborators
export const listSharedWith = listId =>
  createSelector(listCollaborators(listId), collaborators => {
    const sharedWith = collaborators.map(({ name, initials, userId }) => ({
      name,
      initials,
      userId
    }))

    // shared but no collaborators yet
    if (size(sharedWith) < 2) return 'This list is shared'

    return sharedWith
  })
