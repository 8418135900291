import {
  get,
  isDate,
  memoize,
  range,
  fromPairs,
  map,
  defaults,
  orderBy,
  toString
} from 'lodash'
import { flow, omitBy, groupBy, mapValues } from 'lodash/fp'
import moment from 'moment'
import { isCompleted } from './actionFilters'
import { getFirst } from './get'

var mapValuesWithKey = mapValues.convert({ cap: false })

const rollover = true
export const dueformat = 'YYYYMMDD'

export const formatAsDue = momentDate => parseInt(momentDate.format(dueformat))

moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[last] dddd',
    sameElse: 'L'
  },
  // used in date picker
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
})

export const unixNow = () =>
  moment()
    .utc()
    .unix()

export const dateNow = () =>
  moment()
    .utc()
    .toDate()

export const changeLocale = locale => {
  clearLocaleCache()
  // TODO solve moment locale bundling
  // require(`moment/locale/${locale}.js`)
  moment.locale(locale)
}

export const getTodayDueStr = () => moment().format(dueformat)
export const getTodayDueNum = () => parseInt(getTodayDueStr())

const isDueToday = due => {
  return toString(due) === getTodayDueStr()
}

const isDueTomorrow = due => {
  const tomorrowStr = moment()
    .add('1', 'day')
    .format(dueformat)
  return toString(due) === tomorrowStr
}

export const nextWeek = () => moment().add(1, 'week')

export const parseDue = due => {
  const date = moment(toString(due), dueformat)
  if (!date.isValid() || date.unix() < 2 * 24 * 60 * 60 || date.year() > 2050) {
    // check for zero unix time (including tz offset)
    // or too far in future (e.g. off by 1000x)
    const error = new Error(`Invalid date - ${due} does not match ${dueformat}`)
    error.name = 'InvalidDate'
    error.date = due
    throw error
  }
  return date
}

const inPast = due => parseDue(due).isBefore()
export const inFuture = due =>
  parseDue(due).isSameOrAfter(nextWeek().startOf('day'))

export const getDueDay = action => get(action, 'due.day', null)

export const isSomeday = action => !!get(action, 'due.later', false)

export const getScheduleDate = memoize(
  action => {
    // Schedule date - return string from action
    // - rolls over past to today
    // - later than a week is "later"
    // - otherwise returns "YYYYMMDD" formatted string

    let due = getDueDay(action)
    // TODO tz

    // Roll over actions in past to today if rollover enabled
    try {
      if (due && rollover && inPast(due)) {
        due = getTodayDueStr()
      }
    } catch (error) {
      console.error('Invalid date', action)
    }

    if (isSomeday(action)) {
      return 'someday'
    }

    // If older than next week then group as 'later'
    if (due && inFuture(due)) {
      due = 'later'
    }

    return due
  },
  action => (action == null ? null : action.due)
)

export const humanReadableDateFormat = date => {
  // Show the year for dates not in this year
  const showYear = date.year() !== moment().year()
  return date.format(`ddd D MMM${showYear ? ' Y' : ''}`)
}

export const getDisplayDate = memoize(
  (action, inSchedule = false) => {
    // action --> "Fri Dec 14" or "This Week" / "Later" string
    // TODO i18n

    if (isSomeday(action)) {
      if (inSchedule) return null
      return 'Someday'
    }

    const due = getDueDay(action)
    if (!due) {
      return null
    }

    if (isDueToday(due)) {
      return 'Today'
    } else if (isDueTomorrow(due)) {
      return 'Tomorrow'
    }

    const dueDate = parseDue(due)
    return humanReadableDateFormat(dueDate)
  },
  (action, inSchedule) => [get(action, 'due', null), inSchedule]
)

export const getDisplayCompletionDate = memoize(
  action => {
    const completionDate = get(action, 'completionDate', null)
    if (completionDate == null) return null

    // parse to moment obj
    if (!isDate(completionDate)) {
      console.warn('completionDate is not a date', completionDate)
      return null
    }

    const date = moment(completionDate)
    if (!date.isValid()) return null

    // detect yesterday
    const yesterday = moment().subtract(1, 'days')
    if (date.isSame(yesterday, 'day')) return 'Yesterday'

    // detect today
    const today = moment()
    if (date.isSame(today, 'day')) return 'Today'

    return humanReadableDateFormat(date)
  },
  action => get(action, 'completionDate', null)
)

export const getScheduleObject = memoize(() => {
  const today = moment().startOf('day')
  const days = range(7).map(n =>
    today
      .clone()
      .add(n, 'days')
      .format(dueformat)
  ) // today, tomorrow, etc
  const keys = [...days, 'later', 'someday']
  // create object with empty list as value
  // nicer way to do this?
  return fromPairs(map(keys, k => [k, []]))
})

const getScheduleDisplayIndex = action =>
  getFirst(action, ['myData.scheduleDisplayIndex', 'scheduleDisplayIndex'])

export const groupByDate = actions => {
  // Groups actions by date, and adds empty arrays for other days this week
  const grouped = flow(
    omitBy(isCompleted),
    groupBy(getScheduleDate),
    mapValuesWithKey((actions, key) => {
      const sortFields =
        key === 'later'
          ? // sort Later section in Schedule by due day
            ['due.day']
          : // otherwise sort by schedule index, then due day
            [getScheduleDisplayIndex, 'due.day']

      return orderBy(actions, sortFields)
    })
  )(actions)

  // drop no date
  delete grouped.null

  return defaults(grouped, getScheduleObject())
}

export const scheduleDisplayDate = memoize(date => {
  // date is action.due.date, 'later' or 'someday'
  // -> "Today", "Tomorrow", "Monday", ..., "Later"
  if (date === 'later') {
    return 'Later'
  } else if (date === 'someday') {
    return 'Someday'
  } else {
    return parseDue(date).calendar()
  }
})

const clearCache = func => {
  func.cache = new memoize.Cache()
}

export const clearLocaleCache = () => {
  // on change locale clear cache of functions that cache locale dependent dates
  clearCache(getScheduleDate)
  clearCache(getDisplayDate)
  clearCache(getScheduleObject)
  clearCache(scheduleDisplayDate)
}

export const clearTodayCache = () => {
  // on new day clear cache of functions that cache "today"
  clearCache(getScheduleDate)
  clearCache(getDisplayDate)
  clearCache(getScheduleObject)
  clearCache(scheduleDisplayDate)
}

export const getTZOffset = () => {
  const d = new Date()
  const offset = d.getTimezoneOffset() * 60
  // prevent -0
  return offset === 0 ? 0 : -offset
}

export const getScheduledRepeatTime = dueDay => {
  const repeatTime = moment(
    // parse in given timezone
    `${dueDay}`,
    'YYYYMMDD'
  )
    // forwards to midnight
    .add(1, 'day')
    .startOf('day')
    .utc()
    .toDate()

  return repeatTime
}

export const getScheduleDaySubheading = date => {
  if (date === 'later') return 'Scheduled in the future'
  if (date === 'someday') return 'When you have time'

  // e.g. January 1
  return parseDue(date).format('D MMMM')
}

export const getReadableScheduleDate = due => {
  if (due === 'later') return 'Later'
  if (due === 'someday') return 'Someday'

  const date = parseDue(due)
  // Monday, 1st of November
  return date.format('dddd Do of MMMM')
}
