import React, { forwardRef } from 'react'
import { Flex } from 'rebass'
import { getContrastingBorder } from '../../utils/color'

export const Avatar = forwardRef(
  (
    { bg = 'black', color = 'white', size = 40, fontSize = 13, ...props },
    ref
  ) => (
    <Flex
      bg={bg}
      css={{
        borderRadius: 100,
        height: size,
        width: size,
        fontWeight: 800,
        lineHeight: 0,
        boxShadow: getContrastingBorder(bg)
      }}
      justifyContent="center"
      alignItems="center"
      fontSize={fontSize}
      color={color}
      ref={ref}
      {...props}
    />
  )
)
