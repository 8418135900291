import React from 'react'
import { useServiceWorker } from '../serviceWorker'
import { CircularButton } from './ui/CircularButton'
import { DownloadCloud } from 'react-feather'
import { useTooltipRebuild } from './utils/tooltip'

const AppUpdateIcon = () => {
  const { assetsUpdateReady, updateAssets } = useServiceWorker()
  useTooltipRebuild([assetsUpdateReady])

  if (assetsUpdateReady) {
    return (
      <CircularButton
        width={34}
        height={34}
        borderRadius={5}
        transparent={true}
        color="#ffffff"
        data-tip="Update available. Click to reload"
        aria-label="App update available. Click to reload"
        onClick={() => {
          updateAssets()
        }}
      >
        <DownloadCloud />
      </CircularButton>
    )
  }
  return null
}

export default AppUpdateIcon
